import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dock from 'react-dock';
import NavItem from './NavItem';
import NavItemExpandable from './NavItemExpandable';
import RecentlyVisited from './recentlyVisited';
import Logo from '../Logo';
import { Close } from '@cimpress/react-components/lib/shapes/hamburger';
import { getMenuItems, getSideNav } from '../services/config';
import FALLBACK_NAV_LINKS from './headerFallbackNavLinks';

export default class SideNav extends Component {
  state = {};
  componentDidMount = () => {
    if (this.state.sideNavItems) {
      // items have already been loaded, so we're good.
      return;
    }
    this._isMounted = true;

    getSideNav().then(responseObj => {
      if (!this._isMounted) {
        return false;
      }
      this.setState({ sideNavItems: responseObj });
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { sideNavOpen, toggleSideNav, language, environment, accessToken, sessionId } = this.props;
    const { sideNavItems } = this.state;
    const { heroItems, footerItems } = sideNavItems || {};
    return (
      <Dock
        isVisible={sideNavOpen}
        onVisibleChange={toggleSideNav}
        size={340}
        fluid={false}
        dimStyle={{ background: 'rgba(255, 255, 255, 0.5)' }}>
        <div
          className="sidenav"
          style={{
            position: 'initial',
            width: 'auto',
            height: 'auto',
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <nav className="navbar navbar-inverse clickable" onClick={toggleSideNav}>
            <span className="hamburger">
              <Close />
            </span>
            <a className="navbar-brand">
              <Logo width="107px" placement="sideNav" />
            </a>
          </nav>
          <div className="list-group">
            {heroItems
              ? heroItems.map(item => (
                  <NavItem
                    item={{
                      id: item.id,
                      text: item.text,
                      svg: item.svg,
                      url: item.url,
                    }}
                    isActive={true}
                    key={item.id}
                  />
                ))
              : null}

            <RecentlyVisited accessToken={accessToken} sessionId={sessionId} />
          </div>
          <hr style={{ marginBottom: '20px' }} />

          <StandardSideNavLinks language={language} environment={environment} />

          <div className="list-group" style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexGrow: '1' }} />
            <hr style={{ marginBottom: '20px' }} />
            {footerItems
              ? footerItems.map(item => (
                  <NavItem
                    item={{
                      id: item.id,
                      text: item.text,
                      svg: item.svg,
                      url: item.url,
                    }}
                    isActive={true}
                    key={item.id}
                  />
                ))
              : null}
          </div>
        </div>
      </Dock>
    );
  }
}

SideNav.propTypes = {
  sideNavOpen: PropTypes.bool,
  toggleSideNav: PropTypes.func,
  language: PropTypes.string,
  environment: PropTypes.string,
  accessToken: PropTypes.string,
  sessionId: PropTypes.string,
};

class StandardSideNavLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemList: null,
    };
  }

  componentDidMount() {
    if (this.state.itemList) {
      // items have already been loaded, so we're good.
      return;
    }

    this._isMounted = true;

    getMenuItems().then(responseObj => {
      if (!this._isMounted) {
        return false;
      }
      this.setState({ itemList: responseObj.items });
    });
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    let itemList = this.state.itemList;

    if (!itemList) {
      // should we be using an empty list here?
      // this indicates we haven't yet gotten any response back from the fetch, but are being forced to render anyway...
      itemList = FALLBACK_NAV_LINKS;
    }

    const items = itemList.map(item => {
      if (!item.text) {
        return;
      }

      const isActive = this.props.activeNavLinkId === item.id ? 'active' : '';
      return item.subItems ? (
        <NavItemExpandable item={item} isActive={isActive} key={item.id} />
      ) : (
        <NavItem item={item} isActive={isActive} key={item.id} />
      );
    });

    return <div className="list-group">{items}</div>;
  }
}

const linkPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    glyphicon: PropTypes.string,
    iconUrl: PropTypes.string,
    text: PropTypes.string,
  })
);

StandardSideNavLinks.propTypes = {
  language: PropTypes.string,
  environment: PropTypes.string,
  activeNavLinkId: PropTypes.string,
  fallbackNavLinks: linkPropType,
};
