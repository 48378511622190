import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from '@cimpress/react-components/lib/Button';
import { cx } from 'emotion';

import { flexParentCenter } from '../shared/flexStyles';
import { tenPxMarginRight, thirtyPxMarginTop } from '../shared/commonStyles';
import { intlShape } from '../shared/propTypes';

import messages from './messages';

class RuleOrDataSheetPrompt extends PureComponent {
  static propTypes = {
    onAddConstraint: PropTypes.func.isRequired,
    onAddDataSheet: PropTypes.func.isRequired,
    onDecided: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    intl: PropTypes.shape(intlShape).isRequired,
  };

  onCreateRuleClick = () => {
    const { name } = this.props;

    this.props.onAddConstraint(name);
    this.props.onDecided();
  };

  onCreateDataSheetClick = () => {
    const { name } = this.props;

    this.props.onAddDataSheet(name);
    this.props.onDecided();
  };

  render() {
    const {
      name,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="card">
        <div className="card-header">
          <b>{name}</b>
        </div>
        <div className="card-block">
          <h5 className="text-center">{formatMessage(messages.whatWantToCreate)}</h5>
          <div className={cx(flexParentCenter, thirtyPxMarginTop)}>
            <div className={tenPxMarginRight}>
              <Button type="primary" size="lg" onClick={this.onCreateRuleClick}>
                {formatMessage(messages.rule)}
              </Button>
            </div>
            <Button type="primary" size="lg" onClick={this.onCreateDataSheetClick}>
              {formatMessage(messages.dataSheet)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(RuleOrDataSheetPrompt);
