import { defineMessages } from 'react-intl';

export default defineMessages({
  minMax: {
    id: 'RangeValueEditor.minMax',
    defaultMessage: 'Min-Max',
  },
  incrementWithoutUnit: {
    id: 'RangeValueEditor.incrementWithoutUnit',
    defaultMessage: 'Increment',
  },
  addNew: {
    id: 'RangeValueEditor.addNew',
    defaultMessage: 'Add New',
  },
});
