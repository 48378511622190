import fetchWithAuth from './fetchWithAuth';

const getProductData = async ({ productID, version, accessToken }) => {
  let route = `v1/products/${productID}/versions/${version}`;

  if (version.toLowerCase() === 'current') {
    route = `v1/products/${productID}:current`;
  }
  let product;
  try {
    product = await fetchWithAuth({ route: route, accessToken });
  } catch (error) {
    route = `v1/products/${productID}:latest`;
    product = await fetchWithAuth({ route: route, accessToken });
  }
  return product;
};

export default getProductData;
