import queryString from 'query-string';
import config from './config';
import { buildOptions, fetchRequest } from '../utils/serviceHelper';

const ruleApiUri = `${config.services.ruleApi.uri}`;

export const getRuleSet = async ({ token, referenceId }) => {
  const url = queryString.stringifyUrl({
    url: `${ruleApiUri}/v1/ruleSets:authoritative/${referenceId}`,
  });

  try {
    const ruleset = await fetchRequest(url, buildOptions(token));
    return ruleset;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return null;
};

export default getRuleSet;
