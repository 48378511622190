import { invertedValueTypeIdentifiers } from './Constants/ValueTypeIdentifiers';

const AttributeModelParser = {
  serialize: attributeModelState => attributeModelState.map((model) => {
    let attributeType = '';
    switch (model.attributeType) {
      case 'text':
        attributeType = 'string';
        break;
      case 'number':
        attributeType = 'numeric';
        break;
      case 'formula':
        attributeType = 'numeric';
        break;
      case 'range':
        attributeType = 'numeric';
        break;
      default:
        attributeType = 'string';
    }
    return {
      key: model.attributeName,
      values: model.attributeValue,
      type: attributeType,
      isNullable: !model.isRequired,
    };
  }),
  deserialize: attributeModel => attributeModel.map((model) => {
    const type = model.values.length > 0 ? invertedValueTypeIdentifiers[model.values[0].type] : model.type;
    return {
      attributeName: model.key,
      attributeValue: model.values,
      attributeType: type,
      isRequired: !model.isNullable,
    };
  }),
};

export default AttributeModelParser;
