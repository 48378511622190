import React, { useEffect } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import Tag from '@cimpress/react-components/lib/Tag';
import { generateRangeTranslation } from './helper';

const label = css`
  font-weight: 800;
  font-size: 12px;
  text-decoration: underline;
  margin-left: 6px;
`;

const DataExpander = ({ data, count, tagCss }) => {
  const { formatMessage } = useIntl();

  const [displayData, setDisplayData] = useState([]);
  const [seeMore, setSeeMore] = useState(true);

  useEffect(() => {
    const dataList = data.length > 0 ? data.slice(0, seeMore ? count : data.length) : null;
    setDisplayData(dataList);
    // eslint-disable-next-line
  }, [seeMore, data]);

  const toggleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  return (
    <div>
      {displayData &&
        displayData.map((value) => (
          <Tag
            key={value.range ? JSON.stringify(value.range) : value.value}
            value={value.range ? generateRangeTranslation(formatMessage, value.range) : value.value}
            style={tagCss}
          />
        ))}
      {data.length > count && (
        /* eslint-disable-next-line */
        <a className={cx(label)} onClick={toggleSeeMore} role="button">
          {seeMore ? 'see more' : 'see less'}
        </a>
      )}
    </div>
  );
};

DataExpander.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  count: PropTypes.number.isRequired,
};

export default DataExpander;
