import { LanguageMode } from '@cimpress-technology/formula-editor';

const callRegex = /[_A-Za-z][_0-9A-Za-z]*(?=\s*\()/;

const identifierRegex = /[_A-Za-z][_0-9A-Za-z]*/;

const closeParenthesisRegex = /\)/;

const numberRegex = /[0-9]+(?:\.[0-9]+)?(?:[Ee][+-]?[0-9]{1,3})?/;

const operatorRegex = /(?:[-*/^%,[\]{}.:]|\+\+?|->?|[=!<>]=?)/;

const openParenthesisRegex = /\(/;

const variableRegex = /#{.*?}/;

const whitespaceRegex = /\s+/;

export default LanguageMode.create('rules')
  .addHints('on-path')
  .addDefaultState(state =>
    state
      .addRule('number', numberRegex)
      .addRule('identifier', variableRegex)
      .addRule('call', callRegex)
      .addRule('identifier', identifierRegex)
      .addRule('operator', openParenthesisRegex, { indent: true })
      .addRule('operator', closeParenthesisRegex, { dedent: true })
      .addRule('operator', operatorRegex)
      .addRule('whitespace', whitespaceRegex)
  );

export const functionCompletions = [['clamp'], ['abs'], ['floor'], ['ceil'], ['round']];
