export const RANGE_CONTAINS = 'rangeContains';
export const EQUALS = 'equals';
export const FORMULA = 'formula';

export const FORMULA_TYPES = {
  FORMULA: 'formula',
  GREATER_THAN: 'greaterThan',
  GREATER_THAN_EQUAL_TO: 'greaterThanEqualTo',
  LESS_THAN: 'lessThan',
  LESS_THAN_EQUAL_TO: 'lessThanEqualTo',
};

export const SELECTION_TYPES = {
  SELECT: 'SELECT',
  FORMULA: 'FORMULA',
};

export const OPERATOR_TYPES = {
  IN: `in`,
  NOT_IN: `notIn`,
  GREATER_THAN: `greaterThan`,
  LESS_THAN: `lessThan`,
  GREATER_THAN_OR_EQUAL_TO: `greaterThanOrEqualTo`,
  LESS_THAN_OR_EQUAL_TO: `lessThanOrEqualTo`,
};

export const SELECT_OPERATOR_TYPES = {
  IN: `in`,
  NOT_IN: `notIn`,
};
