import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { VALUE } from '../../shared/enums/options';
import { valueShape, atttributeShape } from '../../shared/propTypes';
import AddForm from '../../shared/addForm';

import { StringValuePill } from './StringValuePill';
import { StringValuesSelector } from './StringValuesSelector';

export const StringValuesEditor = ({ values, attribute, onValuesChanged }) => {
  const onValueRemoved = (value) => {
    const updatedValues = values.filter((selectedValue) => selectedValue.value !== value.value);

    onValuesChanged(updatedValues);
  };

  const onValueChanged = (originalValue, updatedValue) => {
    const updatedValues = [...values];
    const orignalIndex = updatedValues.findIndex((searchValue) => searchValue.value === originalValue.value);

    if (orignalIndex >= 0) {
      updatedValues.splice(orignalIndex, 1, updatedValue);

      onValuesChanged(updatedValues);
    }
  };

  const onAdd = (newValue) => {
    let valuesClone = cloneDeep(values);
    valuesClone.push({ type: VALUE, value: newValue });

    onValuesChanged(valuesClone);
  };

  const onMultiAdd = (newValues) => {
    const uniqueNewValues = validateNewValues(newValues);
    let valuesClone = cloneDeep(values);
    valuesClone = valuesClone.concat(
      uniqueNewValues.map((newValue) => {
        if (validateValue(newValue) === null) {
          return { type: VALUE, value: newValue };
        } else {
          return undefined;
        }
      })
    );

    onValuesChanged(valuesClone.filter((x) => x !== undefined));
  };

  const validateNewValues = (newValues) => {
    return newValues.reduce((uniqueNewValues, newValue) => {
      if (uniqueNewValues.every((value) => value.toLowerCase() !== newValue.toLowerCase()))
        uniqueNewValues.push(newValue);
      return uniqueNewValues;
    }, []);
  };

  const validateValue = (newValue) => {
    let returnValue = null;
    values.forEach((value) => {
      if (value.value.toLowerCase() === newValue.toLowerCase()) {
        returnValue = `Value [${newValue}] is not unique`;
      }
    });
    return returnValue;
  };

  return (
    <Fragment>
      {attribute ? (
        <StringValuesSelector
          selectedValues={values}
          availableValues={attribute ? attribute.values : null}
          onSelectedValuesChanged={onValuesChanged}
          data-testid="StringValuesSelector"
        />
      ) : (
        <AddForm onAdd={onAdd} validateOption={validateValue} data-testid="AddForm" onMultiAdd={onMultiAdd} />
      )}

      {values.map((value) => (
        <StringValuePill
          key={value.value}
          value={value}
          values={attribute ? attribute.values : values}
          canEdit={!Boolean(attribute)}
          onValueRemoved={onValueRemoved}
          onValueChanged={onValueChanged}
          data-testid="StringValuePill"
        />
      ))}
    </Fragment>
  );
};

StringValuesEditor.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape(valueShape)),
  attribute: PropTypes.shape(atttributeShape),
  onValuesChanged: PropTypes.func.isRequired,
};

StringValuesEditor.defaultProps = {
  values: [],
};
