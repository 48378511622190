import { defineMessages } from 'react-intl';

export default defineMessages({
  enterFormula: {
    id: 'FormulaValueEditor.enterFormula',
    defaultMessage: 'Enter a formula',
  },
  closeFormulaInfo: {
    id: 'FormulaInfoModal.closeFormulaInfo',
    defaultMessage: 'Close',
  },
  formulaInstructionsTitle: {
    id: 'FormulaInfoModal.formulaInstructionsTitle',
    defaultMessage: 'Formula Instructions',
  },
  formulaInstructions: {
    id: 'FormulaInfoModal.formulaInstructions',
    defaultMessage:
      'Product formulas allow you to constrain an attribute using excel-like text.\n\n' +
      'The formulas may contain other attributes as well as numeric values, pi.\n\n' +
      'You can simply assign another numeric attribute’s value by typing that attribute’s name..\n\n' +
      '`Page Count`.\n\n' +
      'You can perform basic add (+), subtract (-), multiply (*), divide (/), exponent (^), and modulo' +
      ' (%) as well as use parentheses to control order of operation..\n\n' +
      '`(Width * Height) / 1000`.   \n\n' +
      '##### Several functions are available that work like they do in Excel. ' +
      'The numbers in the examples may be replaced with attributes, ie “Trim Width” instead of 16..\n\n' +
      'Square Root.\n\n' +
      '`sqrt(16)` - results in the value 4.\n\n' +
      'Clamp – used to keep a value between two extremes.\n\n' +
      '`clamp(Trim Width, 10, 20)` - if Trim Width is greater than 20, 20 is used, ' +
      'if Trim Width is less than 10, 10 is used, otherwise Trim Width is used..\n\n' +
      'Absolute Value – will convert negative numbers to positive.\n\n' +
      '`abs(-5)` - results in the value 5.\n\n' +
      'Floor – rounds down.\n\n' +
      '`floor(5.7)` - results in the value 5.\n\n' +
      'Ceiling – rounds up.\n\n' +
      '`ceil(5.7)` - results in the value 6.\n\n' +
      'Round – rounds to nearest.\n\n' +
      '`round(5.7)` - results in the value 6.',
  },
});
