const getAttributeMap = ({
  type, required, attributeValues, attributeRanges,
}) => {
  let mappedType = 'text';
  let values = [];
  switch (type) {
    case 'listOfRanges':
      mappedType = 'range';
      break;
    case 'formula':
      mappedType = 'formula';
      break;
    case 'listOfValues':
      mappedType = 'text';
      values = attributeValues.map(value => value.value);
      break;
    default:
      mappedType = 'text';
  }

  if (mappedType === 'range') {
    return { type: mappedType, required, ranges: attributeRanges };
  }
  return { type: mappedType, required, values };
};

export default getAttributeMap;
