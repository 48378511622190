import React, { Component } from 'react';
import { Button } from '@cimpress/react-components';
import { FormulaValueEditor } from '@cimpress-technology/react-product-experience-rules';
import './AttributeDefinition.css';
import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import AttributeModelContextConsumer from '../Context/Consumers/AttributeModelContextConsumer';

class AttributeFormulaDefinition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {},
    };
  }

  onValueChange = (value) => {
    this.setState({ value: value.formula });
  }

  onValueAdded = () => {
    this.props.onAttributeValueAdded(this.state.value);
    this.setState({ value: {} });
  }

  render() {
    const formulaVariables = reduce(this.props.attributeModel, (result, model) => {
      if (['range', 'formula', 'number'].includes(model.attributeType)) {
        result.push(model.attributeName);
      }
      return result;
    }, []);

    return (
      <div className="formula-container">
        <FormulaValueEditor
          value={this.state.value}
          variableNames={formulaVariables}
          onValueChanged={this.onValueChange}
        />

        <Button
          type="default"
          className="add-value-btn"
          style={{ border: 'none' }}
          disabled={isEmpty(this.state.value)}
          onClick={this.onValueAdded}
        >
          <IconAddCircle size="2x" />
        </Button>
      </div>
    );
  }
}

export default AttributeModelContextConsumer(AttributeFormulaDefinition);
