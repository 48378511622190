import React, { Component } from 'react';
import { Snackbar } from '@cimpress/react-components';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import AttributeListItem from '../AttributeListItem/AttributeListItem';

class AttributeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributesModel: this.props.attributeModels.map(attributeModel => ({ ...attributeModel, expanded: false })),
      selfUpdate: false,
      activeAttribute: '',
      showSnackbar: false,
      errorMessage: '',
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.selfUpdate) {
      return {
        attributesModel: state.attributesModel,
        selfUpdate: false,
      };
    }
    if (nextProps.attributeModels.length > state.attributesModel.length
        && nextProps.attributeModels[0].attributeValue.length === 0) {
      return {
        attributesModel: nextProps.attributeModels.map((attributeModel, index) => {
          if (index === 0) {
            return { ...attributeModel, expanded: true };
          }
          return { ...attributeModel, expanded: false };
        }),
        selfUpdate: false,
        activeAttribute: nextProps.attributeModels[0].attributeName,
      };
    }
    return {
      attributesModel: nextProps.attributeModels.map((attributeModel) => {
        if (attributeModel.attributeName === state.activeAttribute
            && nextProps.attributeModels.length === state.attributesModel.length) {
          return { ...attributeModel, expanded: true };
        }
        return { ...attributeModel, expanded: false };
      }),
      selfUpdate: false,
    };
  }

  onRequiredChanged = (attributeName, value) => {
    this.props.onAttributeModified({ isRequired: value }, attributeName);
  }

  onEditAttributeClicked = (attributeName) => {
    const expandIndex = findIndex(this.state.attributesModel, { attributeName });
    const activeIndex = findIndex(this.state.attributesModel, { expanded: true });
    this.setState((prevState) => {
      const tempState = { ...prevState };
      tempState.attributesModel[expandIndex] = { ...prevState.attributesModel[expandIndex], expanded: true };
      if (activeIndex >= 0) {
        tempState.attributesModel[activeIndex] = { ...prevState.attributesModel[activeIndex], expanded: false };
      }
      return {
        attributesModel: tempState.attributesModel,
        selfUpdate: true,
        activeAttribute: attributeName,
      };
    });
  }

  isValidUpdate = (updatedAttributeName) => {
    const exisitngAttributeIndex = findIndex(this.state.attributesModel, model => model.attributeName === updatedAttributeName);
    if (exisitngAttributeIndex > -1) {
      this.setState({
        showSnackbar: true,
        errorMessage: 'This attribute name already exists.',
        selfUpdate: true,
      });
      return false;
    }
    return true;
  }

  onDoneAttributeClicked = (previousAttributeName, updatedAttributeName) => {
    if (previousAttributeName === updatedAttributeName) {
      const collapsedIndex = findIndex(this.state.attributesModel, { attributeName: previousAttributeName });
      this.setState((prevState) => {
        const tempState = { ...prevState };
        tempState.attributesModel[collapsedIndex] = { ...prevState.attributesModel[collapsedIndex], expanded: false };
        return {
          attributesModel: tempState.attributesModel,
          selfUpdate: true,
          activeAttribute: '',
        };
      });
    } else if (this.isValidUpdate(updatedAttributeName)) {
      const collapsedIndex = findIndex(this.state.attributesModel, { attributeName: previousAttributeName });
      this.setState((prevState) => {
        const tempState = { ...prevState };
        tempState.attributesModel[collapsedIndex] = { ...prevState.attributesModel[collapsedIndex], expanded: false };
        return {
          attributesModel: tempState.attributesModel,
          selfUpdate: true,
          activeAttribute: '',
        };
      },
      () => this.props.onAttributeModified({ attributeName: updatedAttributeName }, previousAttributeName));
    }
  }

  onAttributeValueChanged = (value, attributeName) => {
    this.props.onAttributeModified({ attributeValue: value }, attributeName);
  }

  render() {
    return (
      <div className="attribute-wrapper">
        {this.state.attributesModel.map(attributeModel => (
          <AttributeListItem
            key={attributeModel.attributeName}
            isEditable={this.props.isEditable}
            suggestiveValues={get(this.props.suggestiveModel[attributeModel.attributeName], attributeModel.attributeType === 'range' ? 'ranges' : 'values', [])}
            onEditAttributeClicked={this.onEditAttributeClicked}
            onDoneAttributeClicked={this.onDoneAttributeClicked}
            onRemoveAttribute={this.props.onRemoveAttribute}
            onAttributeValueChanged={this.onAttributeValueChanged}
            onRequiredChanged={this.onRequiredChanged}
            {...attributeModel}
          />
        ))}
        <Snackbar
          show={this.state.showSnackbar}
          bsStyle="danger"
          delay={2000}
          onHideSnackbar={() => this.setState({ showSnackbar: false, errorMessage: '' })}
        >
          {this.state.errorMessage}
        </Snackbar>
      </div>
    );
  }
}

AttributeListItem.propType = {
  attributeModels: PropTypes.array,
  isEditable: PropTypes.bool,
  onRemoveAttribute: PropTypes.func,
  onAttributeModified: PropTypes.func,
};

AttributeListItem.defaultProps = {
  attributeModels: [],
  isEditable: true,
  onAttributeModified: () => null,
  onRemoveAttribute: () => null,
};

export default AttributeList;
