import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import { white, teal } from '@cimpress/react-components/lib/colors';
import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';
import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';
import TextField from '@cimpress/react-components/lib/TextField';
import Autosuggest from 'react-autosuggest';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import trim from 'lodash/fp/trim';
import reject from 'lodash/fp/reject';
import isEmpty from 'lodash/fp/isEmpty';
import uniqWith from 'lodash/fp/uniqWith';
import isEqual from 'lodash/fp/isEqual';

import AdvancedTextField from './advancedTextField';
import { RoundButton } from './styledComponents';

import messages from './messages';
import { intlShape } from './propTypes';

const RETURN = 13;

const addFormLayout = css`
  justify-content: center;
  display: flex;
`;

const attributeSelect = css`
  flex-grow: 8;
  margin-right: 16px;
`;

const smartButtonLayout = css`
  margin-right: 10px;
`;

const renderSuggestion = (suggestion) => <span>{suggestion.label}</span>;

function splitValues(value, delimiters) {
  const splitRegex = new RegExp(`[${delimiters.join('')}]`, 'i');
  const values = (value || '').split(splitRegex);

  return flow(map(trim), reject(isEmpty))(values);
}

class AddForm extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    validateOption: PropTypes.func,
    className: PropTypes.string,
    onAdd: PropTypes.func,
    onMultiAdd: PropTypes.func,
    label: PropTypes.string,
    selectOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    autosuggest: PropTypes.bool,
    intl: PropTypes.shape(intlShape).isRequired,
  };

  static defaultProps = {
    validateOption: () => null,
    className: '',
    onAdd: () => {},
    onMultiAdd: undefined,
    label: '',
    selectOptions: [],
    autosuggest: false,
    disabled: false,
  };

  static renderInputComponent = (inputProps) => <TextField {...inputProps} />;
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      values: [],
      suggestions: props.selectOptions,
      showSmartButton: false,
      listDelimiter: [','],
      errMessage: null,
    };
  }

  onChange = ({ target }) => {
    let value = target.value;
    let values = splitValues(target.value, this.state.listDelimiter);

    this.setState({
      value: value,
    });

    this.setState({
      values: splitValues(target.value, this.state.listDelimiter),
    });

    this.validateOption(value, values);
  };

  onSuggestionSelected = (event, { suggestionValue }) => {
    this.onAdd(suggestionValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: this.props.selectOptions });
  };

  onAdd = (value) => {
    if (!this.props.validateOption(value)) {
      this.props.onAdd(value);
      this.setState({ value: '', errMessage: null });
    }
  };

  onAddListDelimitedValue = (inputValue) => {
    const values = splitValues(inputValue, this.state.listDelimiter);
    if (this.props.onMultiAdd) {
      this.props.onMultiAdd(values);
    } else {
      values.forEach((value) => {
        this.onAdd(value);
      });
    }

    this.setState({ showSmartButton: false });
  };

  onAddListDelimitedValue = (inputValue) => {
    const values = splitValues(inputValue, this.state.listDelimiter);
    this.onMultipleValuesInputed(values);
    this.setState({ showSmartButton: false });
  };

  onMultipleValuesInputed = (values) => {
    const uniqValues = uniqWith(isEqual, values);
    if (this.props.onMultiAdd) {
      this.props.onMultiAdd(uniqValues);
    } else {
      uniqValues.forEach((value) => {
        this.onAdd(value);
      });
    }
  };

  onDelimitedListInputed = (enabled) => {
    this.setState({
      showSmartButton: enabled,
    });
  };

  getSuggestions = (inputValue) =>
    this.props.selectOptions.filter(({ value }) => value.toLowerCase().includes(inputValue.toLowerCase()));

  renderAutosuggest = (suggestions, inputProps) => (
    <Autosuggest
      renderSuggestion={renderSuggestion}
      suggestions={suggestions}
      getSuggestionValue={(suggestion) => suggestion.value}
      shouldRenderSuggestions={() => true}
      inputProps={inputProps}
      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      onSuggestionHighlighted={({ suggestion }) => this.setState({ highlighted: suggestion })}
      renderInputComponent={AddForm.renderInputComponent}
      onSuggestionSelected={this.onSuggestionSelected}
    />
  );

  renderTextField = (inputProps) => <AdvancedTextField {...inputProps} />;

  renderSmartButton = (errMessage) => {
    const { value } = this.state;
    return (
      <div className={smartButtonLayout}>
        <RoundButton type="primary" onClick={() => this.onAddListDelimitedValue(value)} disabled={Boolean(errMessage)}>
          <IconAddCircle size="lg" color={white} />
        </RoundButton>
      </div>
    );
  };

  validateOption = (value, values) => {
    const { validateOption } = this.props;
    if (values.length > 1) {
      let bulkErrMessage = '';
      values.forEach((value) => {
        let test = validateOption(value);
        if (test) {
          bulkErrMessage = bulkErrMessage.concat(test, ' -- ');
        }
      });
      this.setState({ errMessage: bulkErrMessage });
    } else {
      this.setState({ errMessage: validateOption(value) });
    }
  };

  render() {
    const {
      className,
      label,
      autosuggest,
      disabled,
      intl: { formatMessage },
    } = this.props;

    const { value, highlighted, suggestions, showSmartButton, errMessage } = this.state;

    const bsStyle = errMessage ? 'error' : '';

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      bsStyle,
      label: label || formatMessage(messages.add),
      onKeyDown: (e) => (e.keyCode === RETURN && !highlighted ? this.onAdd(value) : null),
      onChange: this.onChange,
      value,
      disabled,
      onMultipleValuesInputed: this.onMultipleValuesInputed,
      onDelimitedListInputed: this.onDelimitedListInputed,
      onEnterPressed: () => this.onAdd(value),
      listDelimiter: this.state.listDelimiter,
      splitValues,
    };

    return (
      <div>
        <div className={cx(addFormLayout, className)}>
          <div className={attributeSelect}>
            {autosuggest ? this.renderAutosuggest(suggestions, inputProps) : this.renderTextField(inputProps)}
          </div>
          {showSmartButton ? this.renderSmartButton(errMessage) : ''}
          <RoundButton type="primary" onClick={() => this.onAdd(value)} disabled={!value || Boolean(errMessage)}>
            <IconAdd size="lg" color={value ? white : teal.base} />
          </RoundButton>
        </div>
        {errMessage ? <span className="help-block">{errMessage}</span> : null}
      </div>
    );
  }
}

export default injectIntl(AddForm);
