/* eslint-disable max-classes-per-file */
export class ExtensibleError extends Error {
  constructor(message, additionalInfo = null) {
    super(message);
    this.name = this.constructor.name;
    this.additionalInfo = additionalInfo;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class ServiceHttpResponseError extends ExtensibleError {
  constructor(message, response, additionalInfo = null) {
    super(message, additionalInfo);

    this.response = response;
    if (response && response.body) {
      this.responseMessage = response.body;
    }
  }

  toString() {
    return `${this.message} - ${this.response.status} (${this.response.statusText})\n\t${this.responseMessage || ''}`;
  }
}

export const buildOptions = (method = 'GET', body, additionalHeaders, accessToken) => {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
    ...additionalHeaders,
  };

  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    headers['Content-Type'] = 'application/json';
  }

  if (body && typeof body !== 'string') {
    // eslint-disable-next-line no-param-reassign
    body = JSON.stringify(body);
  }

  return {
    method,
    mode: 'cors',
    headers,
    body,
  };
};

export const checkResponse = async (res, serviceComment, isSimpleResponse) => {
  let body;
  if (res.ok) {
    if (res.status === 204) {
      return isSimpleResponse ? null : { response: res };
    }
    body = await res.json();
    return isSimpleResponse ? body : { response: res, body };
  }

  const response = {
    status: res.status,
    statusText: res.statusText,
    ok: res.ok,
  };
  response.body = await (/application\/json/.test(res.headers.get('content-type')) ? res.json() : res.text());
  throw new ServiceHttpResponseError(`Error fetching ${serviceComment}`, response);
};
