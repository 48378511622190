import React from 'react';
import PropTypes from 'prop-types';
import { silver, coal } from '@cimpress/react-components/lib/colors';

const FormulaString = ({ children }) => <code style={{ backgroundColor: silver, color: coal }}>{children}</code>;

FormulaString.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormulaString;
