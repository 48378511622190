import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';
import Label from '@cimpress/react-components/lib/Label';
import { silver } from '@cimpress/react-components/lib/colors';

import TextWrapTag from './textWrapTag';
import FormulaString from './formulaString';
import { constraintConditionShape } from './propTypes';

import messages from './messages';
import operatorMessages from './messages/operatorMessages';
import { getRangeMessage } from './formatters/rangeTranslator';

const gridDisplay = css`
  display: grid;
  grid-gap: 5px;
`;

const layouts = {
  resultOnly: css`
    grid-template-columns: auto;
  `,
  singleResult: css`
    grid-template-columns: min-content auto;
  `,
  ruleConstraint: css`
    grid-template-columns: 50% 50%;
  `,
  ruleConstraintContents: css`
    grid-template-columns: min-content auto;
  `,
};

const smallMarginTop = css`
  margin-top: 5px;
`;

// If attribute name is omitted, also omit operator -- property assignment case

const SingleConditionDisplay = ({ attribute, operator, values }) => {
  const { formatMessage } = useIntl();
  return (
    <span>
      {attribute && (
        <Fragment>
          <Label type="info" text={attribute} /> {operator ? formatMessage(operatorMessages[operator]) : null}{' '}
        </Fragment>
      )}
      {values &&
        values.map(value => {
          return value.formula ? (
            <TextWrapTag
              style={{ margin: '3px', backgroundColor: silver }}
              key={value.formula}
              value={<FormulaString>{value.formula}</FormulaString>}
            />
          ) : (
            <TextWrapTag
              style={{ margin: '3px' }}
              key={value.range ? JSON.stringify(value.range) : value.value}
              value={value.range ? formatMessage(getRangeMessage(value.range), value.range) : value.value}
            />
          );
        })}
    </span>
  );
};

SingleConditionDisplay.propTypes = {
  attribute: PropTypes.string,
  operator: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const RuleDisplay = ({ conditions, resultAttribute, result, results }) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className={cx(
        gridDisplay,
        results ? layouts.ruleConstraint : conditions ? layouts.singleResult : layouts.resultOnly
      )}>
      {result ? (
        <div>
          <SingleConditionDisplay attribute={resultAttribute} operator={'in'} values={[result]} />
        </div>
      ) : null}
      {conditions ? (
        <div className={cx(gridDisplay, layouts.ruleConstraintContents)}>
          <div className={smallMarginTop}>{result ? formatMessage(messages.when) : formatMessage(messages.if)}</div>
          <div>
            {conditions.map(condition => (
              <div key={JSON.stringify(condition)}>
                <SingleConditionDisplay
                  attribute={condition.attribute}
                  operator={condition.operator}
                  values={condition.values}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {results ? (
        <div className={cx(gridDisplay, layouts.ruleConstraintContents)}>
          <div className={smallMarginTop}>{formatMessage(messages.then)}</div>
          <div>
            {results.map(result => (
              <div key={JSON.stringify(result)}>
                <SingleConditionDisplay
                  attribute={result.attribute}
                  operator={result.operator}
                  values={result.values}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

RuleDisplay.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
  result: PropTypes.shape(constraintConditionShape),
  results: PropTypes.arrayOf(PropTypes.shape(constraintConditionShape)),
  resultAttribute: PropTypes.string,
};

export default RuleDisplay;
