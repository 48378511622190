import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Select from '@cimpress/react-components/lib/Select';

import { OPERATOR_TYPES, SELECT_OPERATOR_TYPES } from '../../shared/enums/operators';
import { NUMBER, STRING } from '../../shared/enums/options';

import messages from './messages';
import operatorMessages from '../../shared/messages/operatorMessages';

const generateOperator = (operator, formatMessage) => {
  const translatedString = operatorMessages[operator];
  if (!translatedString) {
    return operator;
  }
  return formatMessage(translatedString);
};

export const OperatorSelector = ({ attributeType, selectedOperator, onSelectionChanged }) => {
  const { formatMessage } = useIntl();

  let operatorOptions = [];

  if (attributeType) {
    const validOperations = attributeType === STRING ? SELECT_OPERATOR_TYPES : OPERATOR_TYPES;

    operatorOptions = Object.values(validOperations).map(operatorType => ({
      value: operatorType,
      label: generateOperator(operatorType, formatMessage),
    }));
  }

  return (
    <Select
      id="OperatorSelector"
      clearable={false}
      label={formatMessage(messages.label)}
      value={operatorOptions.filter(option => option.value === selectedOperator)}
      options={operatorOptions}
      onChange={selectedValue => {
        onSelectionChanged(selectedValue.value);
      }}
    />
  );
};

OperatorSelector.propTypes = {
  attributeType: PropTypes.oneOf([NUMBER, STRING]),
  selectedOperator: PropTypes.oneOf([null, '', ...Object.values(OPERATOR_TYPES)]),
  onSelectionChanged: PropTypes.func.isRequired,
};

OperatorSelector.defaultProps = {
  attributeType: null,
  selectedOperator: '',
};

export default OperatorSelector;
