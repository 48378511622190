import React, { Component } from 'react';
import TextField from '@cimpress/react-components/lib/TextField';
import PropTypes from 'prop-types';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import trim from 'lodash/fp/trim';
import reject from 'lodash/fp/reject';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/omit';
import keys from 'lodash/keys';

export const splitValues = (value, delimiters) => {
  const splitRegex = new RegExp(`[${delimiters.join('')}]`, 'i');
  const values = (value || '').split(splitRegex);

  return flow(map(trim), reject(isEmpty))(values);
};

const ENTER_KEY = 13;
const COMPONENT_PROPS = {
  multiValueDelimiter: PropTypes.arrayOf(PropTypes.string),
  listDelimiter: PropTypes.arrayOf(PropTypes.string),
  onEnterPressed: PropTypes.func,
  enableEnterPressed: PropTypes.bool,
  onMultipleValuesInputed: PropTypes.func,
  onDelimitedListInputed: PropTypes.func,
  splitValues: PropTypes.func,
};

class AdvancedTextField extends Component {
  static propTypes = {
    ...COMPONENT_PROPS,
  };

  static defaultProps = {
    ...TextField.defaultProps,
    onEnterPressed: () => {},
    enableEnterPressed: true,
    onMultipleValuesInputed: () => {},
    multiValueDelimiter: ['\r\n', '\t'],
    listDelimiter: [','],
    onDelimitedListInputed: () => {},
    splitValues,
  };

  keyDownHandler = event => {
    if (event.keyCode === ENTER_KEY && this.props.enableEnterPressed) {
      this.props.onEnterPressed();
    } else if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  };

  pasteHandler = event => {
    const values = this.props.splitValues(event.clipboardData.getData('text/plain'), this.props.multiValueDelimiter);

    if (values.length > 1) {
      this.props.onMultipleValuesInputed(values);
      event.preventDefault();
    }
  };

  changeHandler = sourceEvent => {
    if (this.props.onChange) {
      const event = { ...sourceEvent };

      const values = this.props.splitValues(event.target.value, this.props.listDelimiter);
      if (values.length > 1) {
        this.props.onDelimitedListInputed(true);
        event.target.values = values;
      } else {
        this.props.onDelimitedListInputed(false);
        delete event.target.values;
      }

      this.props.onChange(event);
    }
  };

  render() {
    const passThroughProps = omit(this.props, ['onKeyDown', 'onChange', ...keys(COMPONENT_PROPS)]);

    return (
      <TextField
        onKeyDown={this.keyDownHandler}
        onPaste={this.pasteHandler}
        onChange={this.changeHandler}
        {...passThroughProps}
      />
    );
  }
}

export default AdvancedTextField;
