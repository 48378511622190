import { buildOptions, checkResponse } from './serviceHelpers';

const fetchWithAuth = async ({
  method = 'GET',
  route,
  body,
  additionalHeaders = {},
  giveSimpleResponse = true,
  accessToken,
}) => {
  const options = buildOptions(method, body, additionalHeaders, accessToken);
  const baseUrl = 'https://api.products.cimpress.io';

  const fullUrl = `${baseUrl}/${route}`;

  try {
    const rawResponse = await fetch(fullUrl, options);

    const parsedResponse = await checkResponse(rawResponse, `${method} ${fullUrl}`, giveSimpleResponse);

    return parsedResponse;
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    throw error;
  }
};

export default fetchWithAuth;
