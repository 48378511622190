import messages from './messages';

export const getRangeMessage = range => {
  if (range.minimum === range.maximum) {
    return messages.rangeExactly;
  } else if (range.maximum) {
    if (range.increment) {
      return messages.rangeMaximumWithIncrement;
    } else {
      return messages.rangeMaximumNoIncrement;
    }
  } else {
    if (range.increment) {
      return messages.rangeNoMaximumWithIncrement;
    } else {
      return messages.rangeNoMaximumNoIncrement;
    }
  }
};
