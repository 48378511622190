const valueTypeIdentifiers = {
  number: 'numberLiteral',
  text: 'stringLiteral',
  range: 'range',
  formula: 'formula',
};

const invertedValueTypeIdentifiers = {
  numberLiteral: 'number',
  stringLiteral: 'text',
  range: 'range',
  formula: 'formula',
};

export { invertedValueTypeIdentifiers };

export default valueTypeIdentifiers;
