import React from 'react';
import PropTypes from 'prop-types';
import Tag from '@cimpress/react-components/lib/Tag';

const TextWrapTag = props => (
  <Tag {...props} style={{ ...props.style, overflowWrap: 'break-word', maxWidth: '500px' }} />
);

TextWrapTag.propTypes = {
  style: PropTypes.object,
};

TextWrapTag.defaultProps = {
  style: {},
};

export default TextWrapTag;
