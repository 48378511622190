import React from 'react';
import { css, cx } from 'emotion';
import Button from '@cimpress/react-components/lib/Button';

export const roundButtonStyle = css`
  && {
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
  }
`;

export const RoundButton = ({ className, size, type, ...rest }) => (
  <Button
    type={type}
    className={cx(
      roundButtonStyle,
      css`
        && {
          width: ${size ? size : '50px'};
          height: ${size ? size : '50px'};
        }
      `,
      className
    )}
    {...rest}
  />
);
