import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import Card from '@cimpress/react-components/lib/Card';
import DataExpander from './DataExpander';
import { info, shale } from '@cimpress/react-components/lib/colors';
import { css } from 'emotion';
import { segregateValues } from './helper';

const position = css`
  margin-bottom: 20px;
  color: inherit;
`;

const cardHeader = (color) => css`
  color: ${color};
`;

const cardDivCss = (display) => css`
  margin-bottom: 20px;
  color: inherit;
  display: ${display};
`;

const valuesText = css`
  color: ${info.darkest};
  margin: 7px;
  font-size: 13px;
`;

const unchangedValuesText = css`
  color: ${shale};
  margin: 7px;
  font-size: 13px;
`;

const addedValueTag = {
  borderColor: `${info.darkest}`,
  color: `${info.darkest}`,
  margin: `5px`,
};

const removedValueTag = {
  borderColor: `${info.darkest}`,
  color: `${info.darkest}`,
  margin: `5px`,
  textDecoration: `line-through`,
};

export const OptionCard = ({ option, cardCss, v1, count }) => {
  const { formatMessage } = useIntl();

  let addedValues, unchangedValues, removedValues;
  if (!isNil(v1)) {
    addedValues = [];
    unchangedValues = [];
    removedValues = cloneDeep(v1.values);
    segregateValues(unchangedValues, addedValues, removedValues, v1.values, option.values);
  }

  return isNil(v1) ? (
    <Card
      className={position}
      key={option.name}
      header={<div className={cardHeader(cardCss.color)}>{option.name}</div>}
      style={cardCss}>
      {option.values && (
        <DataExpander
          data={option.values}
          count={count}
          tagCss={{
            margin: '5px',
            color: cardCss.color,
            borderColor: cardCss.color,
            textDecoration: cardCss.textDecoration,
          }}
        />
      )}
    </Card>
  ) : (
    <Card header={option.name} className={position}>
      <div style={{ display: 'flex' }}>
        <div className={cardDivCss(!isEmpty(addedValues) ? 'block' : 'none')} style={{ flex: '1' }}>
          <h5 className={valuesText}>{formatMessage(messages.addedValues)}</h5>
          {addedValues && <DataExpander data={addedValues} count={count} tagCss={addedValueTag} />}
        </div>
        <div className={cardDivCss(!isEmpty(removedValues) ? 'block' : 'none')} style={{ flex: '1' }}>
          <h5 className={valuesText}>{formatMessage(messages.removedValues)}</h5>
          {removedValues && <DataExpander data={removedValues} count={count} tagCss={removedValueTag} />}
        </div>
      </div>
      <div className={cardDivCss(!isEmpty(unchangedValues) ? 'block' : 'none')}>
        <h5 className={unchangedValuesText}>{formatMessage(messages.unchangedValues)}</h5>
        {unchangedValues && <DataExpander data={unchangedValues} count={count} tagCss={{ margin: `3px` }} />}
      </div>
    </Card>
  );
};
