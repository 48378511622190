import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Button, Snackbar } from '@cimpress/react-components';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import get from 'lodash/get';
import ErrorBoundary from './utils/ErrorBoundary';
import AttributeCreation from './AttributeCreation/AttributeCreation';
import AttributeList from './AttributeList/AttributeList';
import AttributeModelParser from './AttributeModelParser';
import AttributeModelContext from './Context/AttributeModelContext';
import PublishAttributeModel, { GetAttributeModel } from './services/attributeModelService';
import './GenericAttributeModel.css';

class GenericAttributeModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributesModel: [],
      errorMessage: '',
      showSnackbar: false,
      snackbarStyle: 'success',
      resourceUrl: null,
    };
  }

  componentDidMount() {
    if (this.props.resourceUrl) {
      this.loadResourceUrl(this.props.resourceUrl);
    } else if (!isEmpty(this.props.attributeModel)) {
      this.loadAttributeModel(this.props.attributeModel);
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.resourceUrl && this.props.resourceUrl !== prevProps.resourceUrl)
      || (this.props.token && this.props.token !== prevProps.token)) {
      this.loadResourceUrl(this.props.resourceUrl);
    } else if (!isEmpty(this.props.attributeModel) && !isEqual(prevProps.attributeModel, this.props.attributeModel)) {
      this.loadAttributeModel(this.props.attributeModel);
    }
  }

  loadAttributeModel = (attributeModel) => {
    const attributeModelAttributes = get(attributeModel, 'attributes', []);
    const resourceUrl = this.buildResourceUrl(attributeModel);
    this.setState({
      attributesModel: AttributeModelParser.deserialize(attributeModelAttributes),
      resourceUrl,
    });
  }

  buildResourceUrl = (attributeModel) => {
    if (!attributeModel.id || !attributeModel.version) {
      return null;
    }

    const { id, version } = attributeModel;
    const prefix = this.props.isProduction ? '' : 'stg-';
    const resourceUrl = `https://${prefix}attributemodels.products.cimpress.io/v0/attributeModels/${id}/versions/${version}`;
    return resourceUrl;
  }

  loadResourceUrl = async (resourceUrl) => {
    try {
      const response = await GetAttributeModel(this.props.token, resourceUrl);
      this.setState({
        attributesModel: AttributeModelParser.deserialize(response.attributes),
        resourceUrl,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  onNewAttributeAdded = ({ attributeName, attributeType }) => {
    const attributeData = {
      attributeName,
      attributeType,
      attributeValue: [],
      isRequired: true,
    };

    this.setState(prevState => ({
      attributesModel: [attributeData, ...prevState.attributesModel],
    }));
  }

  onAttributeDeleted = (attributeName) => {
    this.setState(prevState => (
      {
        attributesModel: prevState.attributesModel
          .filter(attributeModel => attributeModel.attributeName !== attributeName),
      }
    ));
  }

  onAttributeModified = (modifiedAttributes, attributeName) => {
    const index = findIndex(this.state.attributesModel, { attributeName });
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState.attributesModel[index] = { ...prevState.attributesModel[index], ...modifiedAttributes };
      return {
        attributesModel: newState.attributesModel,
      };
    });
  }

  extractResourceId = (resourceUrl) => {
    let resourceId = null;
    if (resourceUrl) {
      const resourcePath = resourceUrl.split('/attributeModels/');
      [resourceId] = resourcePath[1].split('/');
    }
    return resourceId;
  }

  publishAttributeModel = async () => {
    let responseMessage = '';
    let snackbarStyle = 'success';
    let publishResult = {};

    try {
      const attributeModelResponse = await PublishAttributeModel(this.props.token, this.props.isProduction,
        AttributeModelParser.serialize(this.state.attributesModel), this.extractResourceId(this.state.resourceUrl));
      const resourceUrl = get(attributeModelResponse, '_links.self.href', '');
      const { attributesModel } = this.state;
      const status = { statusCode: 200, statusMessage: 'OK' };
      this.props.onPublish(status, resourceUrl, attributesModel);
      responseMessage = 'Attribute model published provided the URI.';
      publishResult = { status, resourceUrl, attributesModel };
    } catch (error) {
      const errorCode = get(error, 'response.status', 500);
      const errorMessage = get(error, 'response.statusText', 'Something went wrong');
      const resourceUrl = null;
      const { attributesModel } = this.state;
      const status = { statusCode: errorCode, statusMessage: errorMessage };
      this.props.onPublish(status, resourceUrl, attributesModel);
      responseMessage = `${errorCode}: Failed to publish attribute model on server.`;
      snackbarStyle = 'danger';
      publishResult = { status, resourceUrl, attributesModel };
    }

    this.setState({
      showSnackbar: true,
      errorMessage: responseMessage,
      snackbarStyle,
    });

    return publishResult;
  }

  onPublish = async () => {
    let publishAttributeModelResponse = {};
    if (isEmpty(this.state.attributesModel)) {
      const attributesModel = [];
      const resourceUrl = null;
      const status = { statusCode: 400, statusMessage: 'No Attribute(s) are defined' };
      publishAttributeModelResponse = {
        status, attributesModel, resourceUrl,
      };
      this.props.onPublish(status, resourceUrl, attributesModel);
    } else if (filter(this.state.attributesModel, model => model.attributeValue.length === 0).length > 0) {
      const statusMessage = 'Some attribute(s) have no values assigned.';
      const attributesModel = [];
      const resourceUrl = null;
      const status = { statusCode: 400, statusMessage };
      publishAttributeModelResponse = {
        status, attributesModel, resourceUrl,
      };

      this.props.onPublish(status, resourceUrl, attributesModel);
      this.setState({
        showSnackbar: true,
        errorMessage: statusMessage,
        snackbarStyle: 'danger',
      });
    } else {
      publishAttributeModelResponse = await this.publishAttributeModel();
    }

    return publishAttributeModelResponse;
  }

  hideSnackbar = () => this.setState({ showSnackbar: false, errorMessage: '' });

  render() {
    const attributes = map(this.state.attributesModel, attributeModel => attributeModel.attributeName);
    const newAttributeSuggestions = {};
    const exisitngAttributeSuggestions = {};
    forEach(Object.keys(this.props.suggestiveModel), (suggestionName) => {
      if (!attributes.includes(suggestionName)) {
        newAttributeSuggestions[suggestionName] = this.props.suggestiveModel[suggestionName];
      } else {
        exisitngAttributeSuggestions[suggestionName] = this.props.suggestiveModel[suggestionName];
      }
    });

    return (
      <ErrorBoundary>
        <IntlProvider language="en" locale="en">
          <AttributeModelContext.Provider value={this.state.attributesModel}>
            <React.Fragment>
              {!this.props.isReadOnly ? (
                <AttributeCreation
                  suggestiveModel={newAttributeSuggestions}
                  onAttributeAdded={this.onNewAttributeAdded}
                />
              ) : null}
              {!this.props.isReadOnly && this.props.showPublishBtn
                ? (
                  <div className="action-bar">
                    <Button onClick={this.onPublish} disabled={this.state.attributesModel.length === 0}>Publish</Button>
                  </div>
                ) : null}
              <AttributeList
                isEditable={!this.props.isReadOnly}
                suggestiveModel={exisitngAttributeSuggestions}
                attributeModels={this.state.attributesModel}
                onRemoveAttribute={this.onAttributeDeleted}
                onAttributeModified={this.onAttributeModified}
              />
              <Snackbar show={this.props.allowSnackbarMessage && this.state.showSnackbar} bsStyle={this.state.snackbarStyle} delay={2000} onHideSnackbar={this.hideSnackbar}>
                {this.state.errorMessage}
              </Snackbar>
            </React.Fragment>
          </AttributeModelContext.Provider>
        </IntlProvider>
      </ErrorBoundary>
    );
  }
}

GenericAttributeModel.propType = {
  resourceUrl: PropTypes.string,
  suggestiveModel: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['text', 'number', 'range', 'formula']),
        required: PropTypes.bool,
        values: PropTypes.arrayOf(PropTypes.string),
        ranges: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
      }),
    ),
  ),
  onPublish: PropTypes.func,
  token: PropTypes.string.isRequired,
  isProduction: PropTypes.bool,
  showPublishBtn: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  allowSnackbarMessage: PropTypes.bool,
  attributeModel: PropTypes.object,
};

GenericAttributeModel.defaultProps = {
  resourceUrl: null,
  suggestiveModel: [],
  onPublish: () => null,
  isProduction: false,
  showPublishBtn: true,
  isReadOnly: false,
  allowSnackbarMessage: true,
  attributeModel: {},
};

export default GenericAttributeModel;
