import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { getAttributeMapForOptions, getAttributeMapForProperties, getAttributeMapForQuantities } from './productHelper';
import getAttributeMap from './rulesetHelper';

const QUANTITY_ATTRIBUTE_KEY = 'Quantity';

const combineAttributeMap = (prevMap, newMap) => {
  const mergedMap = {};
  const existingMap = prevMap || {
    values: [],
    ranges: [],
    required: true, // default it should be true
  };

  if (newMap.type) {
    if (newMap.type === 'number' || newMap.type === 'range') {
      mergedMap.type = newMap.type === 'range' || existingMap.type === 'range' ? 'range' : newMap.type;

      if (mergedMap.type === 'range') {
        let ranges = existingMap.ranges && existingMap.ranges.length > 0 ? [...existingMap.ranges] : [];
        ranges = newMap.ranges && newMap.ranges.length > 0 ? [...ranges, ...newMap.ranges] : [...ranges];
        mergedMap.ranges = uniq(ranges);
      }
    } else {
      mergedMap.type = newMap.type;
    }
  }

  if (newMap.type === 'text') {
    let values = existingMap.values ? [...existingMap.values] : [];

    values = newMap.values ? [...values, ...newMap.values] : [...values];
    mergedMap.values = uniq(values);
  }

  mergedMap.required = newMap.required && existingMap.required;

  return mergedMap;
};

export const getAggregatedSuggestiveModel = (productDefinitions = []) => {
  const aggregatedAttributeView = {};
  productDefinitions.forEach((productDefinition) => {
    const whitelistAttributes = get(productDefinition, 'whiteList', []);
    whitelistAttributes.forEach((attribute) => {
      const {
        attributeKey, type, required, attributeValues = [], attributeRanges = [],
      } = attribute;
      const existingAttributeView = aggregatedAttributeView[attributeKey];

      const attributeMap = getAttributeMap({
        type, required, attributeValues, attributeRanges,
      });

      aggregatedAttributeView[attributeKey] = combineAttributeMap(existingAttributeView, attributeMap);
    });

    const options = get(productDefinition, 'options', []);
    options.forEach((option) => {
      const {
        name, type, values = [],
      } = option;

      const existingAttributeView = aggregatedAttributeView[name];

      const attributeMap = getAttributeMapForOptions({
        type, required: true, attributeValues: values,
      });

      aggregatedAttributeView[name] = combineAttributeMap(existingAttributeView, attributeMap);
    });


    const properties = get(productDefinition, 'properties', []);
    properties.forEach((property) => {
      const {
        name, staticAssignment, assignments = [],
      } = property;

      const existingAttributeView = aggregatedAttributeView[name];

      const attributeMap = getAttributeMapForProperties({
        required: false, staticAssignment, assignments,
      });

      aggregatedAttributeView[name] = combineAttributeMap(existingAttributeView, attributeMap);
    });


    const quantities = get(productDefinition, 'quantity', []);

    quantities.forEach((quantity) => {
      const {
        type, range = [], value,
      } = quantity;

      const existingAttributeView = aggregatedAttributeView[QUANTITY_ATTRIBUTE_KEY];

      const attributeMap = getAttributeMapForQuantities({
        type, required: true, attributeRange: range, attributeValue: value,
      });

      aggregatedAttributeView[QUANTITY_ATTRIBUTE_KEY] = combineAttributeMap(existingAttributeView, attributeMap);
    });
  });

  return aggregatedAttributeView;
};

export default getAggregatedSuggestiveModel;
