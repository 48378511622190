import { defineMessages } from 'react-intl';

export default defineMessages({
  selectValuesLabel: {
    id: 'StringValuesSelector.selectValuesLabel',
    defaultMessage: 'Select Values...',
  },
  addValuesLabel: {
    id: 'StringValuesSelector.addValuesLabel',
    defaultMessage: 'Add Values...',
  },
  invalidInput: {
    id: 'StringValuePill.invalidInput',
    defaultMessage: 'Value already exists',
  },
});
