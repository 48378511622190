import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import VersionsWrapper from './VersionsWrapper';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const VersionsDiffChecker = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <VersionsWrapper {...props} />
    </QueryClientProvider>
  );
};
