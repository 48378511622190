import cloneDeep from 'lodash/cloneDeep';

const createRule = name => ({
  name,
  description: '',
  conditions: [],
  results: [],
});

const createDataSheet = name => ({
  name,
  rows: [],
});

const createCondition = (condition, option) => {
  return {
    attribute: '',
    operator: undefined,
  };
};

export const addRule = (rules = [], name) => {
  return [createRule(name), ...cloneDeep(rules)];
};

export const addDataSheet = (dataSheets = [], name) => {
  return [createDataSheet(name), ...cloneDeep(dataSheets)];
};

export const deleteDataSheet = (dataSheets, index) => {
  const newDataSheets = cloneDeep(dataSheets);
  newDataSheets.splice(index, 1);

  return newDataSheets;
};

export const updateRule = (rules, name, rule) => {
  const newRules = cloneDeep(rules);
  return (
    rules && newRules.map(ruleValue => (ruleValue.name === name ? rule : ruleValue)).filter(ruleValue => ruleValue)
  );
};

export const addCondition = rule => {
  return { ...cloneDeep(rule), conditions: rule.conditions.concat([createCondition()]) };
};

export const updateCondition = (rule, originalCondition, condition) => {
  return { ...rule, conditions: rule.conditions.map(value => (value === originalCondition ? condition : value)) };
};

export const removeCondition = (rule, originalCondition, condition) => {
  return { ...rule, conditions: rule.conditions.filter(value => value !== originalCondition) };
};

export const addResult = rule => {
  return { ...cloneDeep(rule), results: rule.results.concat([createCondition()]) };
};

export const updateResult = (rule, originalResult, result) => {
  return { ...rule, results: rule.results.map(value => (value === originalResult ? result : value)) };
};

export const removeResult = (rule, originalResult) => {
  return { ...rule, results: rule.results.filter(value => value !== originalResult) };
};
