import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import translations from '../i18n/locales';

import { ConditionEditor, conditionEditorPropTypes } from '../shared/conditionEditor';

class ConditionEditorContainer extends Component {
  static propTypes = {
    ...conditionEditorPropTypes,
    locale: PropTypes.string.isRequired,
  };

  render() {
    const { locale } = this.props;

    const messages = translations[locale] || {};

    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <ConditionEditor {...this.props} />
      </IntlProvider>
    );
  }
}

export default ConditionEditorContainer;
