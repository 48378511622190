function getConvertedAttributeRange(attributeRange) {
  const updatedAttributeRange = {};
  Object.keys(attributeRange).forEach((key) => { updatedAttributeRange[key] = Number(attributeRange[key]); });
  return updatedAttributeRange;
}

export const getAttributeMapForOptions = ({
  type, required, attributeValues,
}) => {
  let mappedType = 'text';
  let values = [];
  switch (type) {
    case 'number':
      mappedType = 'range';
      break;
    case 'string':
      mappedType = 'text';
      values = attributeValues.map(attributeValue => attributeValue.value);
      break;
    default:
      mappedType = 'text';
  }

  if (mappedType === 'range') {
    const ranges = attributeValues.map((attributeValue) => {
      const value = Number(attributeValue.value);
      if (value) {
        return {
          minimum: value,
          maximum: value,
        };
      }

      return getConvertedAttributeRange(attributeValue.range);
    });
    return { type: mappedType, required, ranges };
  }

  return { type: mappedType, required, values };
};

export const getAttributeMapForProperties = ({
  required, staticAssignment, assignments,
}) => {
  let mappedType = 'text';
  const values = [];

  if (staticAssignment) {
    switch (staticAssignment.type) {
      case 'value':
        mappedType = 'text';
        values.push(staticAssignment.value);
        break;
      case 'formula':
        mappedType = 'formula';
        break;
      default:
        mappedType = 'text';
    }
  } else if (assignments) {
    assignments.forEach((assignment) => {
      switch (assignment.result.type) {
        case 'value':
          mappedType = 'text';
          values.push(assignment.result.value);
          break;
        case 'formula':
          mappedType = 'formula';
          break;
        default:
          mappedType = 'text';
      }
    });
  }

  return { type: mappedType, required, values };
};

export const getAttributeMapForQuantities = ({
  type, required, attributeRange, attributeValue,
}) => {
  let mappedType = 'range';
  const ranges = [];
  switch (type) {
    case 'range':
      ranges.push(getConvertedAttributeRange(attributeRange));
      break;
    case 'value':
      const value = Number(attributeValue);
      ranges.push({ minimum: value, maximum: value });
      break;
    default:
      mappedType = 'range';
  }

  return { type: mappedType, required, ranges };
};
