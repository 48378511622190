import { defineMessages } from 'react-intl';

export default defineMessages({
  add: {
    id: 'addForm.add',
    defaultMessage: 'Add',
  },
  unspecifiedType: {
    id: 'conditionEditor.unspecifiedType',
    defaultMessage: 'Unspecified Attribute Type!',
  },
  ranges: {
    id: 'numericValueSelector.ranges',
    defaultMessage: 'Ranges',
  },
  formula: {
    id: 'numericValueSelector.formula',
    defaultMessage: 'Formula',
  },
  number: {
    id: 'numericValueSelector.number',
    defaultMessage: 'Number',
  },
  when: {
    id: 'assignmentRuleSummary.when',
    defaultMessage: 'When',
  },
  then: {
    id: 'assignmentRuleSummary.then',
    defaultMessage: 'Then',
  },
  if: {
    id: 'assignmentRuleSummary.if',
    defaultMessage: 'If',
  },
  addCondition: {
    id: 'conditionEditor.addCondition',
    defaultMessage: 'Add condition',
  },
  noConditionsAddToStart: {
    id: 'conditionEditor.noConditionsAddToStart',
    defaultMessage: 'No conditions for this rule. Add one to get started.',
  },
  optionLabel: {
    id: 'conditionEditor.optionLabel',
    defaultMessage: 'Option/Property',
  },
});
