import React from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import Tag from '@cimpress/react-components/lib/Tag';

import ConstraintCard from './constraintCard';

import { dataSheetsShape } from '../shared/propTypes';

const DataSheetSummary = ({ dataSheet, deleteKey, onDelete, onEdit }) => {
  const onDeleteClick = () => onDelete(deleteKey);
  const onEditClick = () => onEdit();

  const attributes = uniq(dataSheet.rows.length && dataSheet.rows[0].terms.map(term => term.key));

  return (
    <ConstraintCard
      title={<b>{dataSheet.name}</b>}
      content={
        <div className="card-block">
          {attributes && attributes.map(att => <Tag style={{ margin: '3px' }} key={att} label={att} />)}
        </div>
      }
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
    />
  );
};

DataSheetSummary.propTypes = {
  dataSheet: PropTypes.arrayOf(PropTypes.shape(dataSheetsShape)).isRequired,
  deleteKey: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DataSheetSummary;
