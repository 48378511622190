import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import InlineEdit from '@cimpress/react-components/lib/InlineEdit';

import RuleDisplay from '../shared/ruleDisplay';
import ConstraintCard from './constraintCard';

import messages from './messages';
import { constraintRuleShape } from '../shared/propTypes';
import { validateRuleName } from '../shared/validators/keyValidators';
import ConstraintEditor from './constraintEditor';
import cloneDeep from 'lodash/cloneDeep';

const ConstraintComponent = props => {
  const { options, constraintRule, constraintRules, onChange, addCompleteOptionConstraint, errorMessage } = props;
  const { formatMessage } = useIntl();
  const [editing, setEditing] = useState(!(constraintRule.conditions.length && constraintRule.results.length));
  const [nameError, setNameError] = useState(null);

  if (constraintRule.conditions.length === 0 && constraintRule.results.length === 0) {
    setTimeout(
      () =>
        onChange(constraintRule.name, {
          ...constraintRule,
          conditions: [
            {
              attribute: '',
              operator: undefined,
            },
          ],
          results: [
            {
              attribute: '',
              operator: undefined,
            },
          ],
        }),
      0
    );
  }

  // TODO reattach this?
  const validateName = newName => {
    if (!newName) {
      return formatMessage(messages.mustProvideRuleName);
    }
    return validateRuleName(
      newName,
      constraintRules.filter(value => value !== constraintRule),
      'Rule'
    );
  };

  const onUpdateName = e => {
    const nameInvalid = validateName(e.target.value);
    if (nameInvalid) {
      setNameError(nameInvalid);
      setTimeout(() => setNameError(null), 1500);
      return;
    }
    setNameError(null);
    onChange(constraintRule.name, { ...constraintRule, name: e.target.value });
  };

  const onDelete = () => onChange(constraintRule.name, null);

  const onEditClick = () => setEditing(prevEditing => !prevEditing);

  const onCopy = () => {
    const newConstraint = cloneDeep(constraintRule);
    newConstraint.name = newConstraint.name + ' (copy)';
    addCompleteOptionConstraint(newConstraint);
  };

  return (
    <ConstraintCard
      title={
        <div>
          {nameError ? <div class="text-danger">{nameError}</div> : null}
          <InlineEdit
            value={constraintRule.name}
            onChange={onUpdateName}
            placeholder={formatMessage(messages.optionConstraintName)}
          />
        </div>
      }
      errorMessage={errorMessage}
      editing={editing}
      onEditClick={onEditClick}
      onDeleteClick={onDelete}
      onCopyClick={onCopy}
      content={
        editing ? (
          <ConstraintEditor
            rule={constraintRule}
            constraintRules={constraintRules}
            onDelete={onDelete}
            options={options}
            onChange={onChange}
            errorMessage={errorMessage}
          />
        ) : (
          <div className="card-block">
            <RuleDisplay conditions={constraintRule.conditions} results={constraintRule.results} />
          </div>
        )
      }
    />
  );
};

ConstraintComponent.propTypes = {
  constraintRule: PropTypes.shape(constraintRuleShape),
  constraintRules: PropTypes.arrayOf(PropTypes.shape(constraintRuleShape)),
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

ConstraintComponent.defaultProps = {
  errorMessage: ''
}

export default ConstraintComponent;
