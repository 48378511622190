import { defineMessages } from 'react-intl';

export default defineMessages({
  in: {
    id: 'operator.in',
    defaultMessage: 'is',
  },
  notIn: {
    id: 'operator.notIn',
    defaultMessage: 'is not',
  },
  greaterThan: {
    id: 'operator.greaterThan',
    defaultMessage: 'is greater than',
  },
  lessThan: {
    id: 'operator.lessThan',
    defaultMessage: 'is less than',
  },
  greaterThanOrEqualTo: {
    id: 'operator.greaterThanOrEqualTo',
    defaultMessage: 'is greater than or equal to',
  },
  lessThanOrEqualTo: {
    id: 'operator.lessThanOrEqualTo',
    defaultMessage: 'is less than or equal to',
  },
});
