import React, { useState, Fragment } from 'react';
import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { slate, info, success } from '@cimpress/react-components/lib/colors';
import { css } from 'emotion';
import { OptionCard } from './OptionCard';
import find from 'lodash/find';
import { hasDiffValues } from './helper';

export const OptionsDiffChecker = ({ v1, v2, count = 50 }) => {
  const addedOption = {
    border: `1.5px solid ${success.base}`,
    color: `${info.darkest}`,
  };
  const removedOption = {
    color: `${info.darkest} `,
    textDecoration: `line-through`,
  };

  const navTabStyle = {
    flex: 1,
    overflowX: 'scroll',
    maxHeight: '600px',
  };
  const navBodyStyle = {
    flex: 3,
    paddingLeft: '10px',
  };

  const optionCardTypeText = {
    fontSize: '13px',
  };

  const navBarNotificationStyle = (color) => css`
    width: 7px;
    height: 7px;
    background-color: ${color};
    border-radius: 50%;
    margin-top: 5px;
    margin-left: 5px;
  `;

  const commonType = 'common';
  const addedType = 'added';
  const removedType = 'removed';

  let v1Clone = cloneDeep(v1);
  const existsInV1 = (name, spliceIt = true) => {
    return v1Clone.some((option, index) => {
      if (option.name.toLowerCase() === name.toLowerCase()) {
        spliceIt && v1Clone.splice(index, 1);
        return true;
      }
      return false;
    });
  };

  const [selectedTab, setSelectedTab] = useState(v2[0]);
  const [optionType, setOptionType] = useState(existsInV1(v2[0].name, false) ? commonType : addedType);

  const hasDiff = (option) => {
    let v1Option = find(v1, (v1Option) => v1Option.name.toLowerCase() === option.name.toLowerCase());
    return hasDiffValues(option.values, v1Option.values);
  };

  const generateOptionBody = (selectedOption, type) => {
    if (type === removedType) {
      return (
        <div>
          <h5 style={optionCardTypeText}>Removed Option</h5>
          <OptionCard option={selectedOption} key={selectedOption.name} cardCss={removedOption} count={count} />
        </div>
      );
    }
    if (type === addedType) {
      return (
        <div>
          <h5 style={optionCardTypeText}>Added New</h5>
          <OptionCard option={selectedOption} key={selectedOption.name} cardCss={addedOption} count={count} />
        </div>
      );
    }
    return (
      <OptionCard
        option={selectedOption}
        key={selectedOption.name}
        v1={find(v1, (v1Option) => v1Option.name.toLowerCase() === selectedOption.name.toLowerCase())}
        count={count}
      />
    );
  };

  const setData = (option, type) => {
    setSelectedTab(option);
    setOptionType(type);
  };

  const navBarNotification = (color) => <div className={navBarNotificationStyle(color)}></div>;

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <NavTab vertical={true} style={navTabStyle}>
          {v2.map((option) => {
            return existsInV1(option.name) ? (
              <NavTabItem key={option.name} active={selectedTab.name === option.name}>
                <button onClick={() => setData(option, commonType)} style={{ display: 'flex' }}>
                  {option.name}
                  {hasDiff(option) ? <sup>{navBarNotification(slate)}</sup> : null}
                </button>
              </NavTabItem>
            ) : (
              <NavTabItem key={option.name} active={selectedTab.name === option.name}>
                <button onClick={() => setData(option, addedType)} style={{ display: 'flex' }}>
                  {option.name}
                  <sup>{navBarNotification(success.base)}</sup>
                </button>
              </NavTabItem>
            );
          })}
          {v1Clone.map((option) => {
            return (
              <NavTabItem key={option.name} active={selectedTab.name === option.name}>
                <button
                  onClick={() => setData(option, removedType)}
                  style={{ display: 'flex', textDecoration: `line-through` }}>
                  {option.name}
                </button>
              </NavTabItem>
            );
          })}
        </NavTab>
        <div style={navBodyStyle}>{generateOptionBody(selectedTab, optionType)}</div>
      </div>
    </Fragment>
  );
};

OptionsDiffChecker.propTypes = {
  optionsNew: PropTypes.array,
  optionsOld: PropTypes.array,
};
