import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import NumberValuesEditor from '../NumberValuesEditor';
import { StringValuesEditor } from '../StringValuesEditor';
import { optionShape, propertyConditionAttributeShape, constraintConditionShape } from '../../shared/propTypes';

import messages from './messages';

import { STRING, NUMBER } from '../../shared/enums/options';

const optionType = PropTypes.shape(optionShape);
const propertyType = PropTypes.shape(propertyConditionAttributeShape);

const isOptionType = (option) => option.type === STRING;
const isNumberType = (option) => option.type === NUMBER;

export const ValuesEditor = ({ 
  condition,
  attributes,
  allowFormulas,
  updateConditionValues
}) => {
  const { formatMessage } = useIntl();

  const selectedAttribute = attributes[condition.attribute];

  let editor = null;
  if (!selectedAttribute) {
    editor = null;
  } else if (isOptionType(selectedAttribute)) {
    editor = (
      <StringValuesEditor
        values={condition.values}
        attribute={selectedAttribute}
        onValuesChanged={updateConditionValues}
      />
    );
  } else if (isNumberType(selectedAttribute, condition)) {
    editor = (
      <NumberValuesEditor
        selectedAttribute={selectedAttribute}
        selectedOperator={condition.operator}
        condition={condition}
        attributes={attributes}
        allowFormulas={allowFormulas}
        onUpdateConditionValues={updateConditionValues}
      />
    );
  } else {
    editor = (
      <div>
        <h4>{formatMessage(messages.unspecifiedType)}</h4>
      </div>
    );
  }

  return editor;
};

ValuesEditor.propTypes = {
  condition: PropTypes.shape(constraintConditionShape).isRequired,
  attributes: PropTypes.objectOf(PropTypes.oneOfType([optionType, propertyType])).isRequired,
  allowFormulas: PropTypes.bool,
  updateConditionValues: PropTypes.func.isRequired,
};

ValuesEditor.defaultProps = {
  allowFormulas: true,
};

export default ValuesEditor;
