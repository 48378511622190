/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, shapes } from '@cimpress/react-components';
import { teal } from '@cimpress/react-components/lib/colors';
import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';
import isEmpty from 'lodash/isEmpty';

import getProducts from '../service/searchService';
import styles from './styles';

const getSkus = keyword => keyword.split(/[ ,;\n\t]+/);

const onSkuSearch = async ({
  keyword,
  token,
  onSearchInitiated,
  onSearchCompleted,
}) => {
  let error;
  let records = [];
  if (isEmpty(keyword)) {
    return records;
  }
  try {
    const skus = getSkus(keyword);
    onSearchInitiated(skus);
    records = await getProducts({ skus, token });
  } catch (err) {
    error = err;
    // eslint-disable-next-line no-console
    console.warn(err);
  } finally {
    onSearchCompleted(error, records);
  }

  return records;
};

const SkuSearchBar = ({
  onSearchInitiated,
  onSearchCompleted,
  label,
  token,
  loading,
}) => {
  const [searchText, setSearchText] = useState('');
  const searchProps = {
    onSearchInitiated,
    onSearchCompleted,
    keyword: searchText,
    token,
  };

  const onSearchClick = async (e) => {
    e.preventDefault();
    if (!loading) {
      await onSkuSearch(searchProps);
      setSearchText('');
    }
  };

  useEffect(() => {
    const search = async () => {
      if (!loading) {
        await onSkuSearch(searchProps);
      }
    };

    search();
  }, []);

  const { Spinner } = shapes;
  return (
    <div className={`${styles.searchBoxStyle} ${loading ? 'disabled' : ''}`}>
      <form onSubmit={onSearchClick}>
        <TextField
          value={searchText}
          label={label}
          disabled={loading}
          onChange={e => setSearchText(e.target.value)}
        />
        <a type="link" role="button" onClick={onSearchClick} disabled={loading}>
          {
            loading ? <Spinner size="medium" /> : <IconAdd size="2x" color={teal.base} />
          }
        </a>
      </form>
    </div>
  );
};

SkuSearchBar.propTypes = {
  label: PropTypes.string,
  onSearchCompleted: PropTypes.func,
  onSearchInitiated: PropTypes.func,
};

SkuSearchBar.defaultProps = {
  label: 'Search Skus',
  onSearchCompleted: () => {},
  onSearchInitiated: () => {},
};

export default SkuSearchBar;
