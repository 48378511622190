import isEmpty from 'lodash/isEmpty';
import config from './config';
import { buildOptions, fetchRequest } from '../utils/serviceHelper';

export default function PublishAttributeModel(token, isProduction, attributeModel, attributeModelId) {
  const attributeModelApiURI = isProduction ? config.attributeModelAPI.prd.uri
    : config.attributeModelAPI.stg.uri;
  if (isEmpty(token)) {
    // eslint-disable-next-line no-throw-literal
    throw { response: { status: 403, statusText: 'No auth token provided' } };
  }
  const attributeModelPayload = {
    attributes: attributeModel,
  };
  let method = 'POST';
  let uri = `${attributeModelApiURI}v0/attributeModels`;
  if (!isEmpty(attributeModelId)) {
    method = 'PUT';
    uri += `/${attributeModelId}`;
  }
  const options = buildOptions(token, JSON.stringify(attributeModelPayload), method);

  return fetchRequest(uri, options);
}

export function GetAttributeModel(token, resourceUrl) {
  if (isEmpty(token)) {
    // eslint-disable-next-line no-throw-literal
    throw { response: { status: 403, statusText: 'No auth token provided' } };
  }

  if (isEmpty(resourceUrl)) {
    // eslint-disable-next-line no-throw-literal
    throw { response: { status: 400, statusText: 'No resource url provided' } };
  }

  return fetchRequest(resourceUrl, buildOptions(token, null, 'GET'));
}
