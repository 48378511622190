import { VALUE, RANGE } from '../enums/options';
import { FORMULA } from '../enums/operators';

const FLOAT_REGEX = /^([0-9]*)((\.)?([0-9.]*))?$/i;
const MAX_DECIMAL_LENGTH = 8;

const ERROR_IDS = {
  INVALID_NUMBER: 'numberInvalidFormat',
  INVALID_PRECISION: 'numberInvalidPrecision',
  INVALID_VALUE: 'numberInvalidValue',
};

const isValidNumber = numberString => {
  return !isNaN(numberString);
};

export const isValidPrecision = numberString => {
  const matches = numberString.match(FLOAT_REGEX);

  if (!matches) {
    return false;
  }

  if(Number.isInteger(numberString)) {
    return true;
  }

  var precisionArray = (numberString + "").split(".");
  if(precisionArray.length === 1) {
    return precisionArray[0].length <= MAX_DECIMAL_LENGTH
  } else if(precisionArray.length === 2) {
    return precisionArray[1].length <= MAX_DECIMAL_LENGTH
  }

  return false;
};

const validateNumberInRange = (number, range) => {
  const min = Number(range.minimum);

  if (number < min) {
    return false;
  }

  if (range.maximum) {
    const max = Number(range.maximum);

    if (number > max) {
      return false;
    }
  }

  if (range.increment) {
    const increment = Number(range.increment);

    if (increment >= 1 && (number - min) % increment !== 0) {
      return false;
    } else if (increment < 1 && !Number.isInteger((number - min) / increment)) {
      return false;
    }
  }

  return true;
};

const isValidValue = (numberString, validValues) => {
  const number = Number(numberString);

  return validValues.some(validValue => {
    switch (validValue.type) {
      case VALUE:
        const validNumber = Number(validValue.value);

        return validNumber === number;
      case RANGE:
        return validateNumberInRange(number, validValue.range);
      case FORMULA:
        return true;
      default:
        throw new Error('Encountered unknown value type validating number value');
    }
  });
};

export const validateNumber = (numberString, validValues, enforceValidValues) => {
  const validationResult = {
    numberString,
    errors: [],
    warnings: [],
  };

  if (numberString) {
    if (!isValidNumber(numberString)) {
      validationResult.errors.push({ id: ERROR_IDS.INVALID_NUMBER });
    } else if (!isValidPrecision(numberString)) {
      validationResult.errors.push({ id: ERROR_IDS.INVALID_PRECISION });
    } else if (validValues && !isValidValue(numberString, validValues)) {
      if (enforceValidValues) {
        validationResult.errors.push({ id: ERROR_IDS.INVALID_VALUE });
      } else {
        validationResult.warnings.push({ id: ERROR_IDS.INVALID_VALUE });
      }
    }
  }

  return validationResult;
};
