import React from 'react';
import { Checkbox } from '@cimpress/react-components';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import some from 'lodash/some';
import toNumber from 'lodash/toNumber';
import AttributeRangeDefinition from './AttributeRangeDefinition';
import AttributeValueDefinition from './AttributeValueDefinition';
import AttributeFormulaDefinition from './AttributeFormulaDefinition';
import valueTypeIdentifiers from '../Constants/ValueTypeIdentifiers';

const buildValueByType = (value, type) => {
  const valueType = valueTypeIdentifiers[type];
  const valueObj = {};
  valueObj.type = valueType;
  valueObj[valueType] = value;
  return valueObj;
};

const onAttributeValueAddition = (value, type, callback) => {
  callback(buildValueByType(value, type));
};

const toNumberWithTypeSafety = value => (value ? toNumber(value) : undefined);

const AttributeDefinition = (props) => {
  let definition;
  switch (props.type) {
    case 'text':
      const values = props.values.map(value => value.stringLiteral);
      const filteredSuggestions = filter(props.suggestions, suggestion => !includes(values, suggestion));
      definition = (
        <AttributeValueDefinition
          {...props}
          suggestions={filteredSuggestions}
          onAttributeValueAdded={value => onAttributeValueAddition(value, props.type, props.onAttributeValueAdded)}
        />
      );
      break;
    case 'number':
      definition = (
        <AttributeValueDefinition
          {...props}
          suggestions={[]}
          onAttributeValueAdded={value => onAttributeValueAddition(value, props.type, props.onAttributeValueAdded)}
        />
      );
      break;
    case 'range':
      const ranges = props.values.map(value => value.range);
      const filteredRangeSuggestions = filter(props.suggestions, suggestion => !some(ranges,
        selectedRange => suggestion.minimum === toNumberWithTypeSafety(selectedRange.minimum)
              && suggestion.maximum === toNumberWithTypeSafety(selectedRange.maximum)
              && suggestion.increment === toNumberWithTypeSafety(selectedRange.increment)));

      definition = (
        <AttributeRangeDefinition
          {...props}
          suggestions={filteredRangeSuggestions}
          onAttributeValueAdded={value => onAttributeValueAddition(value, props.type, props.onAttributeValueAdded)}
        />
      );
      break;
    case 'formula':
      definition = (
        <AttributeFormulaDefinition
          {...props}
          onAttributeValueAdded={value => onAttributeValueAddition(value, props.type, props.onAttributeValueAdded)}
        />
      );
      break;
    default:
      definition = (
        <AttributeValueDefinition
          {...props}
          onAttributeValueAdded={value => onAttributeValueAddition(value, props.type, props.onAttributeValueAdded)}
        />
      );
  }
  return (
    <React.Fragment>
      <Checkbox
        checked={props.required}
        onChange={() => props.onRequiredUpdated(!props.required)}
        label="Required"
      />
      {definition}
    </React.Fragment>
  );
};

export default AttributeDefinition;
