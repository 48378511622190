import React from 'react';
import PropTypes from 'prop-types';
import { cx, css } from 'emotion';
import { Tooltip } from '@cimpress/react-components';
import { teal, warning } from '@cimpress/react-components/lib/colors';
import Pencil from '@cimpress-technology/react-streamline-icons/lib/IconPencilAlt';
import Trash from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import Copy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import { tenPxMarginBottom, tenPxMarginLeft } from '../shared/commonStyles';
import { flexParentCenterSpace } from '../shared/flexStyles';

const IconButton = ({ className, disabled, ...rest }) => (
  <button
    className={cx(
      css`
        padding: 0px;
        border: none;
        background: none;
      `,
      'clickable',
      className
    )}
    {...rest}
  />
);

const errorBoarder = css`
   {
    border: solid ${warning.base} 1px !important;
  }
`;

const ConstraintCard = ({ title, content, editing, onDeleteClick, onEditClick, onCopyClick, errorMessage }) => (
  <div className={errorMessage !== '' ? cx('card', tenPxMarginBottom, errorBoarder) : cx('card', tenPxMarginBottom)}>
    <div className={cx('card-header', flexParentCenterSpace)}>
      {title}
      <div>
        {errorMessage !== '' ? (
          <Tooltip direction="top" contents={errorMessage}>
            <IconAlertTriangle weight={'fill'} size="lg" color={warning.base} />
          </Tooltip>
        ) : null}
        <IconButton className={tenPxMarginLeft} type="link" onClick={onEditClick}>
          <Pencil weight={editing ? 'fill' : 'regular'} size="lg" color={teal.base} />
        </IconButton>
        {onCopyClick ? (
          <IconButton className={tenPxMarginLeft} type="link" onClick={onCopyClick}>
            <Copy size="lg" color={teal.base} />
          </IconButton>
        ) : null}
        <IconButton className={tenPxMarginLeft} type="link" onClick={onDeleteClick}>
          <Trash size="lg" color={teal.base} />
        </IconButton>
      </div>
    </div>
    {content}
  </div>
);

ConstraintCard.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  editing: PropTypes.bool,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onCopyClick: PropTypes.func,
  errorMessage: PropTypes.string,
};

ConstraintCard.defaultProps = {
  errorMessage: '',
};

export default ConstraintCard;
