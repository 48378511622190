import queryString from 'query-string';
import config from './config';
import { buildOptions, fetchRequest } from '../utils/serviceHelper';

const productApiUri = `${config.services.productApi.uri}`;

const getV2Product = async ({ token, referenceId }) => {
  const url = queryString.stringifyUrl({
    url: `${productApiUri}/v1/products/${referenceId}:current`,
  });

  try {
    const product = await fetchRequest(url, buildOptions(token));
    return product;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return null;
};

export default getV2Product;
