import React from 'react';
import { fetchBrandingInfo } from '../services/branding';
import { useEffect, useState } from 'react';

export const useBranding = (accessToken, shouldUseBranding, sessionId) => {
  const [isBrandingLoading, setBrandingLoading] = useState(false);
  const [brandingInfo, setBrandingInfo] = useState(null);

  useEffect(
    () => {
      if ((accessToken || sessionId) && shouldUseBranding) {
        setBrandingLoading(true);
        fetchBrandingInfo(accessToken, sessionId)
          .then(branding => {
            setBrandingLoading(false);
            setBrandingInfo(branding);
          })
          .catch(reason => {
            setBrandingLoading(false);
            setBrandingInfo(null);
          });
      }
    },
    [accessToken, shouldUseBranding, sessionId]
  );

  return { isBrandingLoading, brandingInfo };
};
