import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import without from 'lodash/without';

import Button from '@cimpress/react-components/lib/Button';

import ConditionEditor from './conditionEditor';

import { intlShape, optionShape, propertyConditionAttributeShape } from './propTypes';
import { tenPxMarginTop } from './commonStyles';

import messages from './messages';

const PredicateEditor = props => {
  const {
    conditions,
    results,
    attributes,
    addCondition,
    updateCondition,
    removeCondition,
    intl: { formatMessage },
  } = props;

  const usedOptions = [...conditions, ...results].map(condition => condition.attribute).filter(c => c);
  const remainingOptions = without(Object.keys(attributes), ...usedOptions);

  return (
    <div>
      {conditions.map((condition, i) => (
        <Fragment key={condition.attribute || i}>
          <ConditionEditor
            style={tenPxMarginTop}
            condition={condition}
            conditions={conditions}
            results={results}
            attributes={attributes}
            updateCondition={updateCondition}
            removeCondition={removeCondition}
          />
          {i < conditions.length - 1 && <hr />}
        </Fragment>
      ))}
      {!conditions.length && (
        <div>
          <div className="text-muted h5">{formatMessage(messages.noConditionsAddToStart)}</div>
        </div>
      )}
      <Button type="default" className={tenPxMarginTop} onClick={addCondition} disabled={!remainingOptions.length}>
        {formatMessage(messages.addCondition)}
      </Button>
    </div>
  );
};

PredicateEditor.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.shape({})),
  results: PropTypes.arrayOf(PropTypes.shape({})),
  attributes: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.shape(optionShape), PropTypes.shape(propertyConditionAttributeShape)])
  ).isRequired,
  addCondition: PropTypes.func.isRequired,
  updateCondition: PropTypes.func.isRequired,
  removeCondition: PropTypes.func.isRequired,
  intl: PropTypes.shape(intlShape).isRequired,
};

PredicateEditor.defaultProps = {
  conditions: [],
  results: [],
};

export default injectIntl(PredicateEditor);
