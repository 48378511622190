import flatMap from 'lodash/flatMap';

export const ruleValidator = rule => {
  let ruleValidations = [];
  ruleValidations = ruleValidations.concat(validateRuleName(rule.name));
  ruleValidations = ruleValidations.concat(validateConditions(rule.conditions));
  ruleValidations = ruleValidations.concat(validateResults(rule.results));
  return ruleValidations.filter(x => x !== undefined);
};

export const validateRuleName = name => {
  if (!(name && name.length)) {
    return ['Constraints must have a name.'];
  }
  return [];
};

export const validateConditions = conditions => {
  if (!conditions || conditions.length <= 0) {
    return ['Constraints must have at least one condition.'];
  }
  return flatMap(conditions.map(condition => validateCondition(condition)));
};
export const validateCondition = condition => {
  if (!(condition.attribute && condition.operator)) {
    return ['Conditions must have an attribute set.'];
  }
  if (!(condition.values && condition.values.length > 0)) {
    return ['Conditions must have at least 1 value.'];
  }
  return flatMap(condition.values.map(value => validateValue(value)));
};

export const validateResults = results => {
  if (!results || results.length <= 0) {
    return ['Constraints must have at least one result.'];
  }
  return flatMap(results.map(result => validateResult(result)));
};
export const validateResult = result => {
  if (!(result.attribute && result.operator)) {
    return ['Results must have an attribute set.'];
  }
  if (!(result.values && result.values.length > 0)) {
    return ['Results must have at least 1 value.'];
  }
  return flatMap(result.values.map(value => validateValue(value)));
};

export const validateValue = value => {
  if (!value.type) {
    return ['Value malformed.  It must have a type.'];
  }
  switch (value.type) {
    case 'value':
      return validateStringValue(value);
    case 'range':
      return validateRangeValue(value);
    case 'formula':
      return validateFormulaValue(value);
    default:
      return [];
  }
};

export const validateStringValue = value => {
  if (!value.value) {
    return ['A value must be defined.'];
  }
  return [];
};

export const validateFormulaValue = value => {
  if (!value.formula) {
    return ['A formula must be defined.'];
  }
  return [];
};

export const validateRangeValue = value => {
  if (!value.range) {
    return ['A range must be defined'];
  }
  return [];
};
