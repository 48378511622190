import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { atttributeShape } from '../propTypes';

import { FORMULA } from '../enums/operators';
import { mapRangeToText } from '../mappers/range';
import { VALUE, RANGE } from '../enums/options';

import messages from './messages';

const generateAttributeValuesText = attributeValues =>
  attributeValues
    .map(attributeValue => {
      let valueText;

      switch (attributeValue.type) {
        case RANGE:
          valueText = mapRangeToText(attributeValue.range);
          break;
        case VALUE:
          valueText = attributeValue.value;
          break;
        case FORMULA:
          valueText = attributeValue.formula;
          break;
        default:
          valueText = '';
          break;
      }

      return valueText;
    })
    .join(', ');

export const ValidValuesHeader = ({ attribute }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="text-muted">
      {formatMessage(messages.validValuesHeader, { attribute: attribute.name })}
      <div data-testid="ValidValues">{generateAttributeValuesText(attribute.values)}</div>
    </div>
  );
};

ValidValuesHeader.propTypes = {
  attribute: PropTypes.shape(atttributeShape).isRequired,
};
