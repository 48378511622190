import { defineMessages } from 'react-intl';

export default defineMessages({
  rangeSummary: {
    id: 'attributeTable.rangeSummary',
    defaultMessage: '{numberOfRanges} ranges with values from {rangeMin} to {rangeMax}',
  },
  rangeTranslation: {
    id: 'rangeFormatter.rangeTranslation',
    defaultMessage: '{minimum} to {maximum} {increment}',
  },
  rangeTranslationWithExample: {
    id: 'rangeFormatter.rangeTranslationWithExample',
    defaultMessage: '{minimum} to {maximum} {increment}. e.g. {rangeExample}',
  },
  exactly: {
    id: 'rangeFormatter.exactly',
    defaultMessage: 'Exactly {minimum}',
  },
  infinity: {
    id: 'rangeFormatter.infinity',
    defaultMessage: 'infinity',
  },
  incrementAmount: {
    id: 'rangeFormatter.incrementAmount',
    defaultMessage: 'increment {increment}',
  },
  noIncrement: {
    id: 'rangeFormatter.noIncrement',
    defaultMessage: ' no increment',
  },
  addedValues: {
    id: 'optionCard.addedValues',
    defaultMessage: 'Added Values',
  },
  removedValues: {
    id: 'optionCard.removedValues',
    defaultMessage: 'Removed Values',
  },
  unchangedValues: {
    id: 'optionCard.unchangedValues',
    defaultMessage: 'Common in both versions',
  },
});
