/* eslint-disable import/prefer-default-export */
import { css } from 'emotion';

const searchBoxStyle = css`
  width: 100%;
  display: flex;
  position: relative;

  form {
    width: 100%;
  }

  a {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
  }
`;

export default {
  searchBoxStyle,
};
