import { getMcpApps } from '../services/config';
import find from 'lodash.find';

const camelize = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
      return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const getUserRecentLogins = (accessToken, sessionId) =>
  getMcpApps().then(mcpApps => {
    let fetchUrl = 'https://marketplace-service.mex.cimpress.io/v1/user/logins';
    let fetchMethod = 'get';

    if (!accessToken) {
      //reset variables to proxy values
      fetchUrl = `https://sessions.cimpress.io/v1/sessions/proxy?proxyUrl=${fetchUrl}&proxyUrlMethod=${fetchMethod}`;
      fetchMethod = 'post';
    }

    return fetch(fetchUrl, {
      method: fetchMethod,
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : { 'x-session-id': sessionId }),
      }),
      ...(!accessToken && { body: JSON.stringify({}) }),
    }).then(response => {
      return response.json().then(logins => {
        const loginList = Object.keys(logins).map(clientId => ({ clientId, count: logins[clientId] }));
        if (!loginList.length) {
          return null;
        }

        return loginList
          .map(({ clientId, count }) => ({
            clientId,
            count,
            ...(find(mcpApps, mcpApp => mcpApp.clientId === clientId) || {}),
          }))
          .filter(login => login.link && login.id)
          .sort((a, b) => b.count - a.count);
      });
    });
  });
