import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { css, cx } from 'emotion';

import Select, { components } from 'react-select';
import CreateableSelect from 'react-select/creatable';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import SelectWrapper from '@cimpress/react-components/lib/SelectWrapper';
import selectStyles from '@cimpress/react-components/lib/Select/styles';

import { VALUE } from '../../shared/enums/options';
import { valueShape } from '../../shared/propTypes';

import messages from './messages';

const SET_VALUE_ACTION = 'set-value';

const stringValuesSelectorStyle = css`
  .form-group {
    input {
      transition: inherit;
    }
  }
`;

export const StringValuesSelector = ({ selectedValues, availableValues, onSelectedValuesChanged }) => {
  const { formatMessage } = useIntl();
  const [options, updateOptions] = useState({});
  const [selectedOptions, updateSelectedOptions] = useState();
  const [searchText, updateSearchText] = useState('');

  useEffect(() => {
    const optionsHash = availableValues ? generateOptionsHash(availableValues) : generateOptionsHash(selectedValues);

    updateOptions(optionsHash);
  }, [availableValues, selectedValues]);

  useEffect(() => {
    const selected = selectedValues.map((value) => options[value.value]).filter((value) => value);

    updateSelectedOptions(selected);
  }, [options, selectedValues]);

  const onSelectionsChanged = (updatedSelections) => {
    onSelectedValuesChanged(updatedSelections.map((selection) => selection.value));
  };

  const onSearchTextChanged = (updatedSearchText, { action }) => {
    if (action !== SET_VALUE_ACTION) {
      updateSearchText(updatedSearchText);
    }
  };

  const props = {
    id: 'StringValuesSelector',
    placeholder: availableValues ? formatMessage(messages.selectValuesLabel) : formatMessage(messages.addValuesLabel),
    value: selectedOptions,
    options: Object.values(options),
    components: {
      Option: StringValueCheckbox,
    },
    hideSelectedOptions: false,
    controlShouldRenderValue: false,
    closeMenuOnSelect: false,
    backspaceRemovesValue: false,
    tabSelectsValue: false,
    isMulti: true,
    onChange: onSelectionsChanged,
    filterOption: filterFunc,
    getNewOptionData: getNewValue,
    createOptionPosition: 'first',
    styles: selectStyles,
    inputValue: searchText,
    onInputChange: onSearchTextChanged,
    'data-testid': 'StringValuesSelector',
  };

  return (
    <div className={cx(stringValuesSelectorStyle)}>
      <SelectWrapper selectedSelect={availableValues ? Select : CreateableSelect} {...props} />
    </div>
  );
};

StringValuesSelector.propTypes = {
  selectedValues: PropTypes.arrayOf(PropTypes.shape(valueShape)),
  availableValues: PropTypes.arrayOf(PropTypes.shape(valueShape)),
  onSelectedValuesChanged: PropTypes.func.isRequired,
};

StringValuesSelector.defaultProps = {
  selectedValues: [],
};

function generateOptionsHash(values) {
  return values.reduce(
    (hash, value) => ({
      ...hash,
      [value.value]: { label: value.value, value },
    }),
    {}
  );
}

function filterFunc(option, searchText) {
  var optionLabel = option.label.toLowerCase();
  var probe = searchText.toLowerCase();

  return optionLabel.indexOf(probe) > -1;
}

function StringValueCheckbox(props) {
  return (
    <components.Option {...props} isSelected={false}>
      <Checkbox label={props.label} checked={props.isSelected} />
    </components.Option>
  );
}

function getNewValue(inputText, optionLabel) {
  return {
    label: optionLabel,
    value: {
      type: VALUE,
      value: inputText,
    },
  };
}
