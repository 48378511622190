export default {
  attributeModelAPI: {
    stg: {
      uri: 'https://stg-attributemodels.products.cimpress.io/',
    },
    prd: {
      uri: 'https://attributemodels.products.cimpress.io/',
    },
  },
};
