import queryString from 'query-string';
import config from './config';
import { buildOptions, fetchRequest } from '../utils/serviceHelper';

const introductionApiUri = `${config.services.introductionApi.uri}`;

const getProducts = async ({ token, skus }) => {
  const url = queryString.stringifyUrl({
    url: `${introductionApiUri}/v1/products`,
    query: {
      referenceId: skus || [],
    },
  });

  const products = await fetchRequest(url, buildOptions(token));

  return products;
};

export default getProducts;
