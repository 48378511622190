import isEmpty from 'lodash/isEmpty';
import getV2Product from '../service/productService';
import getRuleset from '../service/ruleService';

export const getProductDefinition = async ({ token, referenceId }) => {
  const ruleset = await getRuleset({ token, referenceId });

  if (ruleset) {
    return ruleset;
  }

  // fetch product definition (v2 product and standard product) from product service when v1 ruleset or back migrated v2 product is not found
  const validV2Product = getV2Product({ token, referenceId });
  return validV2Product;
};

export const getAllProductDefinitions = async ({ token, referenceIds = [] }) => {
  const productDefinitionTasks = [];

  referenceIds.forEach(referenceId => productDefinitionTasks.push(getProductDefinition({ token, referenceId })));
  const productDefinitions = await Promise.all(productDefinitionTasks);

  return productDefinitions.filter(productDefinition => !isEmpty(productDefinition));
};
