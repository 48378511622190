import React from 'react';
import AttributeModelContext from '../AttributeModelContext';

const AttributeModelContextConsumer = Component => (
  props => (
    <AttributeModelContext.Consumer>
      {
        attributeModel => <Component attributeModel={[...attributeModel]} {...props} />
    }
    </AttributeModelContext.Consumer>
  )
);

export default AttributeModelContextConsumer;
