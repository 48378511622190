import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import AttributeAccordion from '../AttributeAccordion/AttributeAccordion';
import AttributeDefinition from '../AttributeDefinition/AttributeDefinition';
import valueTypeIdentifiers from '../Constants/ValueTypeIdentifiers';

class AttributeListItem extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  onEditAttribute = () => {
    this.props.onEditAttributeClicked(this.props.attributeName);
  }

  onAttributeValueAdded = (value) => {
    this.props.onAttributeValueChanged(
      uniqBy([...this.props.attributeValue, value], valueTypeIdentifiers[this.props.attributeType]), this.props.attributeName,
    );
  }

  onAttributeValueRemoved = (value) => {
    this.props.onAttributeValueChanged(this.props.attributeValue
      .filter(atrValue => !isEqual(atrValue[valueTypeIdentifiers[this.props.attributeType]],
        value)), this.props.attributeName);
  }

  render() {
    return (
      <div>
        <AttributeAccordion
          title={this.props.attributeName}
          type={this.props.attributeType}
          isEditable={this.props.isEditable}
          values={this.props.attributeValue}
          expanded={this.props.expanded}
          onEditClicked={this.onEditAttribute}
          onCheckClicked={this.props.onDoneAttributeClicked}
          onDeleteClicked={() => this.props.onRemoveAttribute(this.props.attributeName)}
          onValueRemoveClicked={this.onAttributeValueRemoved}
        >
          <AttributeDefinition
            type={this.props.attributeType}
            required={this.props.isRequired}
            values={this.props.attributeValue}
            onAttributeValueAdded={this.onAttributeValueAdded}
            suggestions={this.props.suggestiveValues}
            onRequiredUpdated={value => this.props.onRequiredChanged(this.props.attributeName, value)}
          />
        </AttributeAccordion>
      </div>
    );
  }
}

AttributeListItem.propType = {
  attributeName: PropTypes.string.isRequired,
  attributeType: PropTypes.string.isRequired,
  attributeValue: PropTypes.array,
  expanded: PropTypes.bool,
  isEditable: PropTypes.bool,
  onDoneAttributeClicked: PropTypes.func,
  onRemoveAttribute: PropTypes.func,
  onAttributeValueChanged: PropTypes.func,
  onEditAttributeClicked: PropTypes.func,
  onRequiredChanged: PropTypes.func,
};

AttributeListItem.defaultProps = {
  attributeValue: [],
  expanded: false,
  isEditable: true,
  onDoneAttributeClicked: () => null,
  onRemoveAttribute: () => null,
  onAttributeValueChanged: () => null,
  onEditAttributeClicked: () => null,
  onRequiredChanged: () => null,
};

export default AttributeListItem;
