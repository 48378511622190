import React, { Component } from 'react';
import { Button } from '@cimpress/react-components';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import './AttributeDefinition.css';
import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';
import AutoSuggestTextField from '../AutoSuggestTextField/AutoSuggestTextField';

class AttributeValueDefinition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }


  onValueChange = (event) => {
    this.setState({ value: event.target.value });
  }

  onValueAdded = () => {
    this.props.onAttributeValueAdded(this.state.value);
    this.setState({ value: '' });
  }

  onSuggestionSelected = (event, { suggestionValue }) => {
    this.setState({
      value: suggestionValue,
    });
  }

  render() {
    const inputProps = {
      name: 'attributeValue',
      label: 'Attribute value',
      type: this.props.type,
      helpText: 'Add value(s) for the attribute.',
      onChange: this.onValueChange,
      value: this.state.value,
    };
    const filteredSuggestions = filter(this.props.suggestions, suggestion => includes(suggestion.toLowerCase(), this.state.value.toLowerCase()));

    return (
      <div className="definition-container container-portaling">
        <AutoSuggestTextField
          suggestions={filteredSuggestions}
          inputProps={inputProps}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={this.onSuggestionSelected}
        />
        <Button
          type="default"
          className="add-value-btn"
          style={{ border: 'none' }}
          disabled={this.state.value === ''}
          onClick={this.onValueAdded}
        >
          <IconAddCircle size="2x" />
        </Button>
      </div>
    );
  }
}

export default AttributeValueDefinition;
