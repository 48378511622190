import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import LoadGa from '../Ga/loadGa';
import Logo from '../Logo';
import { Hamburger } from '@cimpress/react-components/lib/shapes/hamburger';
import IconBookOpen from '@cimpress-technology/react-streamline-icons/lib/IconBookOpen';
import IconEmailActionUnread from '@cimpress-technology/react-streamline-icons/lib/IconEmailActionUnread';
import IconCog from '@cimpress-technology/react-streamline-icons/lib/IconCog';
import Profile from '../Profile';
import SideNav from './SideNav';
import Collapse from 'react-smooth-collapse';
import { danger } from '@cimpress/react-components/lib/colors';
import Label from '@cimpress/react-components/lib/Label';
import Dropdown from '@cimpress/react-components/lib/Dropdown';
import { ACCOUNT_CLAIM } from '../services/account';
import { useBranding } from '../branding/useBranding';
import BrandingContext, { BrandingProvider } from '../branding/BrandingContext';

const nonProdStyle = {
  boxShadow: `0 4px 0 0 ${danger.base}`,
};

export const Header = props => {
  const { isBrandingLoading, brandingInfo } = useBranding(props.accessToken, props.useBranding, props.sessionId);

  const branding = {
    shouldUse: props.useBranding,
    data: !isBrandingLoading && (props.accessToken || props.sessionId) ? brandingInfo : null,
  };

  return (
    <BrandingProvider value={branding}>
      <BaseHeader {...props} />
    </BrandingProvider>
  );
};

class BaseHeader extends Component {
  constructor(props) {
    super(props);
    LoadGa();
    this.state = { sideNavOpen: false, requiredSpace: -1, mobileNavOpen: false, forceMobile: false, gaUserSet: false };
    this.toggleSideNav = this.toggleSideNav.bind(this);
    this.setRef = this.setRef.bind(this);
    this.toggleMobileNav = this.toggleMobileNav.bind(this);
    this.calculateResponsive = this.calculateResponsive.bind(this);
    this.updateRequiredSpace = this.updateRequiredSpace.bind(this);
  }

  static contextType = BrandingContext;

  toggleSideNav() {
    this.setState({ sideNavOpen: !this.state.sideNavOpen });
  }

  toggleMobileNav() {
    this.setState({ mobileNavOpen: !this.state.mobileNavOpen });
  }

  setRef(el) {
    if (el) this[el.attributes['data-ref'].value] = el;
  }

  calculateResponsive() {
    if (this.state.mobileNavOpen) this.setState({ mobileNavOpen: false });
    const styles = window.getComputedStyle(this.fluidNav);
    const padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
    const forceMobile = this.fluidNav.clientWidth - padding <= this.state.requiredSpace;
    if (this.state.forceMobile !== forceMobile) this.setState({ forceMobile });
  }

  updateRequiredSpace() {
    const fluidNavTabs = this.fluidNavTabs;
    const fluidNavTitle = this.fluidNavTitle;
    if (
      !this.state.mobileNavOpen &&
      fluidNavTitle &&
      fluidNavTitle.offsetWidth &&
      fluidNavTabs &&
      fluidNavTabs.offsetWidth
    ) {
      const requiredSpace = fluidNavTabs.offsetWidth + fluidNavTitle.offsetWidth;
      if (this.state.requiredSpace < requiredSpace) this.setState({ requiredSpace }, this.calculateResponsive);
    }
  }

  componentDidMount() {
    ReactGA.ga('create', 'UA-65356408-11', 'auto', 'PlatformTracker');
    if (this.props.profile && this.props.profile.sub) {
      ReactGA.ga('PlatformTracker.set', 'userId', this.props.profile.sub);
      this.setState({ gaUserSet: true });
    }
    ReactGA.ga('PlatformTracker.send', 'pageview', window.location.pathname);
    if (this.props.responsive) {
      this.updateRequiredSpace();
      window.addEventListener('resize', this.calculateResponsive);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.responsive && !this.state.mobileNavOpen) {
      this.updateRequiredSpace();
    }
    if (
      this.props.profile &&
      this.props.profile.sub &&
      (prevProps.profile && prevProps.profile.sub) !== (this.props.profile && this.props.profile.sub) &&
      !this.state.gaUserSet
    ) {
      ReactGA.ga('PlatformTracker.set', 'userId', this.props.profile.sub);
      ReactGA.ga('PlatformTracker.send', 'event', 'User', 'logged in after page load');
      this.setState({ gaUserSet: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateResponsive);
  }

  render() {
    const branding = this.context;

    const {
      appTitle,
      appLinks,
      onSettingsClicked,
      notifications,
      documentationUrl,
      mailToAddress,
      accessToken,
      isProduction,
      sessionId,
    } = this.props;
    const mobileNavOpen = this.state.forceMobile && this.state.mobileNavOpen;
    const navLinks = (
      <ul
        onMouseDown={e => (this.state.mobileNavOpen ? e.preventDefault() : undefined)}
        className={`nav nav-tabs ${this.state.forceMobile ? 'nav-stacked' : ''}`}
        style={this.state.forceMobile ? { paddingBottom: 10 } : {}}
        ref={this.setRef}
        data-ref={'fluidNavTabs'}>
        {appLinks.map(obj => {
          const { id, type = 'default', isActive, content, title } = obj;
          const className = isActive ? 'active' : '';
          return type === 'dropdown' ? (
            <Dropdown className={className} key={id} variant="navtab" title={title}>
              {content}
            </Dropdown>
          ) : (
            <li className={className} key={id}>
              {content}
            </li>
          );
        })}
        {onSettingsClicked || documentationUrl || mailToAddress ? <li className="link-divider" /> : null}
        {onSettingsClicked ? (
          <li className="icon-link" key="settings-link">
            <a onClick={onSettingsClicked} className="clickable">
              <IconCog />
            </a>
          </li>
        ) : null}
        {documentationUrl ? (
          <li className="icon-link" key="documentation-link">
            <a aria-label="Documentation" href={documentationUrl} className="clickable">
              <IconBookOpen />
            </a>
          </li>
        ) : null}
        {mailToAddress ? (
          <li className="icon-link" key="mailto-link">
            <a aria-label="Contact Email" href={`mailto:${mailToAddress}`} className="clickable">
              <IconEmailActionUnread />
            </a>
          </li>
        ) : null}
      </ul>
    );
    const showNav =
      this.props.showLeftNav && (!branding.shouldUse || (branding.data && branding.data.header.sideNav.enabled));
    const showLogo = this.props.showLogo;
    const fluidNav = (
      <div className={`navbar-fluid ${this.state.requiredSpace || this.props.appLinks.length === 0 ? '' : 'sizer'}`}>
        <nav
          className="navbar navbar-block subtitle"
          style={isProduction ? undefined : nonProdStyle}
          ref={this.setRef}
          data-ref={'fluidNav'}>
          <div className="navbar-header" ref={this.setRef} data-ref={'fluidNavTitle'}>
            <span
              className="navbar-toggle clickable"
              onClick={this.toggleMobileNav}
              style={this.state.forceMobile ? { outline: 'none', paddingBottom: 10 } : {}}
              tabIndex="2"
              onBlur={() => this.setState({ mobileNavOpen: false })}>
              Menu <span className="caret" />
            </span>
            <h2>{appTitle}</h2>
            {isProduction ? null : (
              <Label
                style={{ marginLeft: '10px', position: 'relative', top: '-3px' }}
                status="danger"
                text="Non-Production"
              />
            )}
            <Collapse expanded={mobileNavOpen}>{navLinks}</Collapse>
          </div>
          <div className={'fluid-collapse navbar-fluid-collapse navbar-right'}>{navLinks}</div>
        </nav>
      </div>
    );
    return (
      <div>
        {showNav ? (
          <SideNav
            sideNavOpen={this.state.sideNavOpen}
            toggleSideNav={this.toggleSideNav}
            language={this.props.language}
            environment={this.props.environment}
            activeNavLinkId={this.props.appId}
            accessToken={accessToken}
            sessionId={sessionId}
          />
        ) : null}
        <nav className="navbar navbar-default">
          {showNav ? (
            <span className="hamburger" onClick={this.toggleSideNav}>
              <Hamburger />
            </span>
          ) : null}
          <div className="navbar-brand" style={!showNav ? { marginLeft: '0px' } : {}}>
            {showLogo ? <Logo width={'107px'} /> : null}
          </div>
          {notifications}
          <div className="dropdown mcp-navbar-userbox">
            <Profile {...this.props} />
          </div>
        </nav>
        {this.state.forceMobile ? <div className="forceMobile" children={fluidNav} /> : fluidNav}
      </div>
    );
  }
}

Header.propTypes = {
  appTitle: PropTypes.node.isRequired,
  appLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['default', 'dropdown']),
      content: PropTypes.node,
      title: PropTypes.string,
      isActive: PropTypes.bool,
    })
  ),
  notifications: PropTypes.node,
  documentation: PropTypes.node,
  mailTo: PropTypes.node,
  appId: PropTypes.string,
  showLeftNav: PropTypes.bool,
  showLogo: PropTypes.bool,
  language: PropTypes.string,
  environment: PropTypes.string,
  isProduction: PropTypes.bool,
  responsive: PropTypes.bool,
  profile: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    domain: PropTypes.string,
    sub: PropTypes.string,
    [ACCOUNT_CLAIM]: PropTypes.string,
  }),
  onSettingsClicked: PropTypes.func,
  onLogOutClicked: PropTypes.func.isRequired,
  onLogInClicked: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  accessToken: PropTypes.string,
  sessionId: PropTypes.string,
};
BaseHeader.propTypes = Header.propTypes;

Header.defaultProps = {
  appLinks: [],
  showLeftNav: true,
  showLogo: true,
  responsive: false,
  language: 'en',
  environment: 'prd',
  isProduction: true,
};
BaseHeader.defaultProps = Header.defaultProps;
