import { css } from 'emotion';

export const flexParentCenter = css`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const flexParentCenterSpace = css`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const flexParent = css`
  && {
    display: flex;
  }
`;
