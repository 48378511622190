import isNil from 'lodash/isNil';

import { mapRangeToText } from '../mappers/range';
import { parseRange, isRangeTextValid } from '../parsers/rangeParser';
import { VALUE, RANGE } from '../enums/options';
import { FORMULA } from '../enums/operators';

const ERROR_IDS = {
  FORMAT_ERROR: 'formatError',
  INVALID_MIN_PRECISION: 'invalidMinPrecision',
  INVALID_MAX_PRECISION: 'invalidMaxPrecision',
  MIN_GREATER_THAN_MAX: 'minGtMax',
  INVALID_INCREMENT_PRECISION: 'invalidIncrementPrecision',
  RANGE_IS_DUPLICATE: 'rangeIsDuplicate',
  INVALID_VALUE: 'rangeInvalidValue',
};

const WARNING_IDS = {
  MAX_NOT_ON_INCREMENT: 'maxNotOnIncrement',
};

const FLOAT_REGEX = /^([0-9]+)((\.)?([0-9.]*))?$/i;
const DECIMAL_IDX = 3;
const MANTISSA_IDX = 4;
const MAX_MANTISSA_LENGTH = 8;

function isValidPrecision(number) {
  const matches = number.toString().match(FLOAT_REGEX);

  if (matches && matches[DECIMAL_IDX]) {
    if (matches[MANTISSA_IDX]) {
      return matches[MANTISSA_IDX].length <= MAX_MANTISSA_LENGTH;
    }
  }

  return true;
}

function getMaximumWarningMessage(range) {
  const max = range.minimum + Math.floor((range.maximum - range.minimum) / range.increment) * range.increment;

  return { id: WARNING_IDS.MAX_NOT_ON_INCREMENT, values: { max } };
}

function validateMaximum(validationResult) {
  const { numericRange } = validationResult;

  if (!isNil(numericRange.maximum)) {
    if (!isValidPrecision(numericRange.maximum)) {
      validationResult.errors.push({ id: ERROR_IDS.INVALID_MAX_PRECISION, values: { precision: MAX_MANTISSA_LENGTH } });
    } else if (!isNil(numericRange.minimum)) {
      if (numericRange.minimum > numericRange.maximum) {
        validationResult.errors.push({ id: ERROR_IDS.MIN_GREATER_THAN_MAX });
      }

      if (!isNil(numericRange.increment)) {
        if ((numericRange.maximum - numericRange.minimum) % numericRange.increment !== 0) {
          validationResult.warnings.push(getMaximumWarningMessage(validationResult.numericRange));
        }
      }
    }
  }
}

function validateMinimum(validationResult) {
  const { range } = validationResult;

  if (!isValidPrecision(range.minimum)) {
    validationResult.errors.push({ id: ERROR_IDS.INVALID_MIN_PRECISION, values: { precision: MAX_MANTISSA_LENGTH } });
  }
}

function validateIncrement(rangeValidation) {
  const { range } = rangeValidation;

  if (!isNil(range.increment)) {
    if (!isValidPrecision(range.increment)) {
      rangeValidation.errors.push({
        id: ERROR_IDS.INVALID_INCREMENT_PRECISION,
        values: { precision: MAX_MANTISSA_LENGTH },
      });
    }
  }
}

function validateRangeStructure(validationResult) {
  validateMinimum(validationResult);
  validateMaximum(validationResult);
  validateIncrement(validationResult);
}

function validateDuplicateRanges(validationResult, existingRangeValues) {
  const existingRangeTexts = existingRangeValues.map(rangeValue => mapRangeToText(rangeValue.range));

  if (existingRangeTexts.find(existingRangeText => existingRangeText === validationResult.rangeString) !== undefined) {
    validationResult.errors.push({ id: ERROR_IDS.RANGE_IS_DUPLICATE });
  }
}

// Validates that a range is a subset of another range
// To be a subset:
// - The test range minimum needs to be within the valid range
// - The test range maximum must be within the valid range
// - If the valid range has an increment,
//    the test range minimum must be on a valid incremental value
// - If the valid range has an increment,
//    the test range increment must be a whole multiple of the valid range increment
const validateRangeIsSubsetOfRange = (testRange, validRange) => {
  const validMinimum = Number(validRange.minimum);

  if (testRange.minimum < validMinimum) {
    return false;
  }

  if (validRange.maximum) {
    if (!testRange.maximum) {
      return false;
    }

    const validMaximum = Number(validRange.maximum);

    if (testRange.minimum > validMaximum) {
      return false;
    }

    if (testRange.maximum !== testRange.minimum && testRange.maximum > validMaximum) {
      return false;
    }
  }

  if (validRange.increment) {
    const validIncrement = Number(validRange.increment);

    if ((testRange.minimum - validMinimum) % validIncrement !== 0) {
      return false;
    }

    if (testRange.maximum !== testRange.minimum) {
      if (!testRange.increment) {
        return false;
      }

      if (testRange.increment % validIncrement !== 0) {
        return false;
      }
    }
  }

  return true;
};

const validateValidValue = (testRange, testValue) => {
  switch (testValue.type) {
    case VALUE:
      if (testRange.minimum !== testRange.maximum) {
        return false;
      }

      return testRange.minimum === Number(testValue.value);
    case RANGE:
      return validateRangeIsSubsetOfRange(testRange, testValue.range);
    case FORMULA:
      return true;
    default:
      throw new Error('Encountered unknown value type validating range value');
  }
};

const validateValidValues = (validationResult, validValues, enforceValidValues) => {
  if (!validValues.some(validValue => validateValidValue(validationResult.numericRange, validValue))) {
    if (enforceValidValues) {
      validationResult.errors.push({ id: ERROR_IDS.INVALID_VALUE });
    } else {
      validationResult.warnings.push({ id: ERROR_IDS.INVALID_VALUE });
    }
  }
};

const convertRange = range => {
  return {
    minimum: Number(range.minimum),
    maximum: range.maximum ? Number(range.maximum) : undefined,
    increment: range.increment ? Number(range.increment) : undefined,
  };
};

export const validateRangeString = (rangeString, existingRangeValues, validValues, enforceValidValues) => {
  const validationResult = {
    rangeString,
    errors: [],
    warnings: [],
  };

  if (rangeString.trim().length !== 0) {
    if (!isRangeTextValid(rangeString)) {
      validationResult.errors.push({ id: ERROR_IDS.FORMAT_ERROR });
    } else {
      validationResult.range = parseRange(rangeString);
      validationResult.numericRange = convertRange(validationResult.range);

      validateRangeStructure(validationResult);

      validateDuplicateRanges(validationResult, existingRangeValues);

      if (validValues) {
        validateValidValues(validationResult, validValues, enforceValidValues);
      }
    }
  }

  return validationResult;
};
