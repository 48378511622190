import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { css } from 'emotion';

import MathEvaluationHelper from '@cimpress-technology/math-evaluation-helper';

import SharedFormulaEditor, { EditorState, Completions } from '@cimpress-technology/formula-editor';
import '@cimpress-technology/formula-editor/lib/styles.css';

import { FormulaInfoModal } from './FormulaInfoModal';

import { valueShape } from '../../shared/propTypes';

import { FORMULA } from '../../shared/enums/operators';

import formulaDefinition, { functionCompletions } from './formulaDefinition';

import messages from './messages';
import { useMemo } from 'react';

SharedFormulaEditor.registerLanguage(formulaDefinition);

const positionRelative = css`
   {
    position: relative;
  }
`;

const positionInfoModal = css`
   {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 50;
  }
`;

const validateValue = (value) => {
  if (!value) {
    throw new Error('FormulaValueEditor did not receive a valid value');
  } else if (value.type && value.type !== FORMULA) {
    throw new Error('FormulaValueEditor received a non-formula value');
  }
};

const computeEditorState = (value) => {
  return value && value.formula ? EditorState.createFromText(value.formula) : EditorState.createEmpty();
};

export const FormulaValueEditor = ({ value, variableNames, onValueChanged }) => {
  const { formatMessage } = useIntl();

  const [editorState, updateEditorState] = useState(() => computeEditorState(value));
  const [completions, updateCompletions] = useState(Completions.create([...functionCompletions]));
  const [validationContext, updateValidationContext] = useState({});
  const [validationState, updateValidationState] = useState({});

  const variableNamesJSON = JSON.stringify(variableNames);
  const memoizedVariableNames = useMemo(() => JSON.parse(variableNamesJSON), [variableNamesJSON]);
  // now memoizedVariableNames is only a new array instance if its contents have actually changed, so we can use it as a dependency for useEffects

  useEffect(() => {
    validateValue(value);
  }, [value]);

  const computeValidationState = useMemo(() => {
    return (editorState) => {
      const formula = EditorState.getText(editorState);

      try {
        MathEvaluationHelper.validate(formula, validationContext);
        updateValidationState({ formula });
      } catch (err) {
        updateValidationState({ formula, validationError: err.message });
      }
    };
  }, [validationContext]);

  useEffect(() => {
    const variableCompletions = memoizedVariableNames.map((variableName) => [`#{${variableName}}`]);
    const newCompletions = Completions.create([...functionCompletions, ...variableCompletions]);

    updateCompletions(newCompletions);

    const validVariables = memoizedVariableNames.reduce(
      (accumulator, variableName) => ({ ...accumulator, [variableName]: 1 }),
      {}
    );

    updateValidationContext(validVariables);
  }, [memoizedVariableNames]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    computeValidationState(editorState);
  }, [editorState, computeValidationState]);

  useEffect(() => {
    if (validationState.formula && validationState.formula !== value.formula) {
      onValueChanged({ type: FORMULA, formula: validationState.formula });
    }
  }, [validationState, onValueChanged]);

  if (value) {
    return (
      <div className={positionRelative}>
        <FormulaInfoModal className={positionInfoModal} />
        <SharedFormulaEditor
          label={formatMessage(messages.enterFormula)}
          editorState={editorState}
          completions={completions}
          language={'rules'}
          onChange={updateEditorState}
          data-testid="FormulaInput"
        />
        {validationState.validationError ? (
          <span className="text-danger" data-testid="FormulaError">
            {validationState.validationError}
          </span>
        ) : null}
      </div>
    );
  }

  return null;
};

FormulaValueEditor.propTypes = {
  value: PropTypes.shape(valueShape),
  variableNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onValueChanged: PropTypes.func.isRequired,
};

FormulaValueEditor.defaultProps = {
  value: { type: FORMULA },
};
