import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import { constraintRuleShape, optionShape, dataSheetsShape } from '../shared/propTypes';
import translations from '../i18n/locales';

import ConstraintsOverviewComponent from './constraintsOverviewComponent';

export class ConstraintsOverviewContainer extends Component {
  static propTypes = {
    constraints: PropTypes.shape({
      dataSheets: PropTypes.arrayOf(PropTypes.shape(dataSheetsShape)),
      rules: PropTypes.arrayOf(PropTypes.shape(constraintRuleShape)),
    }).isRequired,
    options: PropTypes.objectOf(PropTypes.shape(optionShape)).isRequired,
    locale: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    allowDataSheets: PropTypes.bool,
    shouldPaginate: PropTypes.bool,
    itemsPerPage: PropTypes.number,
    getRuleError: PropTypes.func,
  };

  static defaultProps = {
    options: {},
    locale: 'en',
    allowDataSheets: false,
    getRuleError: () => ''
  };

  render() {
    const { constraints, options, locale, allowDataSheets, onChange, shouldPaginate, itemsPerPage, getRuleError } = this.props;

    const messages = translations[locale] || {};

    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <ConstraintsOverviewComponent
          options={options}
          constraints={constraints}
          onChange={onChange}
          allowDataSheets={allowDataSheets}
          shouldPaginate={shouldPaginate}
          itemsPerPage={itemsPerPage}
          getRuleError={getRuleError}
        />
      </IntlProvider>
    );
  }
}

export default ConstraintsOverviewContainer;
