import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import translations from '../../i18n/locales';

const LocalizationProvider = (WrappedComponent) => {
  const renderer = (props) => {
    const { locale } = props;

    const renderProps = { ...props };
    delete renderProps['locale'];

    const messages = translations[locale] || {};

    return (
      <IntlProvider locale={locale} messages={messages}>
        <WrappedComponent {...renderProps} />
      </IntlProvider>
    );
  };

  renderer.propTypes = {
    locale: PropTypes.string,
    ...WrappedComponent.propTypes,
  };

  renderer.defaultProps = {
    locale: 'en',
  };

  renderer.displayName = `LocalizationProvider(${getDisplayName(WrappedComponent)})`;

  return renderer;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default LocalizationProvider;
