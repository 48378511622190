/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
import React, { Component } from 'react';
import {
  Accordion, Tag, Snackbar, Tooltip,
} from '@cimpress/react-components';
import PropTypes from 'prop-types';
import IconPencilAlt from '@cimpress-technology/react-streamline-icons/lib/IconPencilAlt';
import IconCheck from '@cimpress-technology/react-streamline-icons/lib/IconCheck';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import IconNumeric from '@cimpress-technology/react-streamline-icons/lib/IconNumeric';
import IconCommonFileText from '@cimpress-technology/react-streamline-icons/lib/IconCommonFileText';
import IconDataTransferHorizontal from '@cimpress-technology/react-streamline-icons/lib/IconDataTransferHorizontal';
import IconHierarchyNode from '@cimpress-technology/react-streamline-icons/lib/IconHierarchyNode';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import startCase from 'lodash/startCase';
import valueTypeIdentifiers from '../Constants/ValueTypeIdentifiers';

import './AttributeAccordion.css';

class AttributeAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedTitle: this.props.title,
      showSnackbar: false,
      errorMessage: '',
    };
  }

  onHeaderClicked = (event) => {
    event.stopPropagation();
  }

  onAttributeNameChange = (event) => {
    this.setState({
      updatedTitle: event.target.value,
    });
  }

  onSubmitCheck = () => {
    if (this.state.updatedTitle === '') {
      this.setState({
        showSnackbar: true,
        errorMessage: 'Attribute Name cannot be empty',
      });
    } else {
      this.props.onCheckClicked(this.props.title, this.state.updatedTitle);
    }
  }

  getTypeIcon = () => {
    switch (this.props.type) {
      case 'text':
        return <IconCommonFileText size="lg" />;
      case 'number':
        return <IconNumeric size="lg" />;
      case 'range':
        return <IconDataTransferHorizontal size="lg" />;
      case 'formula':
        return <IconHierarchyNode size="lg" />;
      default:
        return <IconCommonFileText size="lg" />;
    }
  }

  accordionHeader = () => (
    <div
      role="heading"
      onClick={this.onHeaderClicked}
      className="attribute-accordion-header"
    >
      <div className="title-container">
        <span className="type-icon"><Tooltip direction="top" contents={startCase(this.props.type)}>{this.getTypeIcon()}</Tooltip></span>
        {this.props.expanded
          ? <input type="text" className="attribute-name-input" onChange={this.onAttributeNameChange} value={this.state.updatedTitle} />
          : this.props.title}
      </div>
      {
        this.props.isEditable ? (
          <div className="header-icons">
            {this.props.expanded
              ? <IconCheck onClick={this.onSubmitCheck} size="lg" />
              : <IconPencilAlt onClick={this.props.onEditClicked} size="lg" />}
            <IconBin onClick={this.props.onDeleteClicked} size="lg" />
          </div>
        ) : null
      }
    </div>
  )

  buildReadableValue = (value) => {
    if (this.props.type === 'range' || this.props.type === 'number') {
      if (value.type === 'range') {
        const rangeObj = value[valueTypeIdentifiers[value.type]];
        return `${rangeObj.minimum} to ${rangeObj.maximum !== undefined ? rangeObj.maximum : 'Infinity'}
           ${rangeObj.increment !== undefined ? `increment ${rangeObj.increment}` : ''}`;
      } if (value.type === 'numberLiteral') {
        return `${value.numberLiteral} to ${value.numberLiteral} ${''}`;
      }
    }
    return value[valueTypeIdentifiers[this.props.type]];
  }

  hideSnackbar = () => this.setState({ showSnackbar: false, errorMessage: '' });

  render() {
    return (
      <div className="attribute-accordion">
        <Accordion
          customOpen={this.props.expanded}
          title={this.accordionHeader()}
        >
          { this.props.expanded ? this.props.children : ''}
        </Accordion>
        <div className="attribute-value-preview">
          {this.props.values.map((value) => {
            const label = this.buildReadableValue(value);
            return (
              <Tag
                key={label}
                value={value[valueTypeIdentifiers[this.props.type]]}
                label={label}
                removable={this.props.expanded}
                onRemoveClick={this.props.onValueRemoveClicked}
              />
            );
          })}
          {!this.props.expanded && this.props.values.length === 0
            ? (
              <span className="warning-icon">
                <Tooltip direction="top" contents="Value(s) are required for an attribute"><IconAlertTriangle weight="fill" size="lg" /></Tooltip>
              </span>
            )
            : ''}
        </div>
        <Snackbar show={this.state.showSnackbar} bsStyle="danger" delay={1000} onHideSnackbar={this.hideSnackbar}>
          {this.state.errorMessage}
        </Snackbar>
      </div>
    );
  }
}

AttributeAccordion.propType = {
  isEditable: PropTypes.bool,
  expanded: PropTypes.bool,
  onEditClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onCheckClicked: PropTypes.func,
  onValueRemoveClicked: PropTypes.func,
  title: PropTypes.node.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
  })),
};

AttributeAccordion.defaultProps = {
  isEditable: false,
  expanded: false,
  onEditClicked: () => null,
  onDeleteClicked: () => null,
  onCheckClicked: () => null,
  onValueRemoveClicked: () => null,
  values: [],
};

export default AttributeAccordion;
