import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './AttributeCreation.css';
import { Select, Button } from '@cimpress/react-components';
import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import AttributeModelContextConsumer from '../Context/Consumers/AttributeModelContextConsumer';
import AutoSuggestTextField from '../AutoSuggestTextField/AutoSuggestTextField';

class AttributeCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributeName: '',
      attributeType: null,
    };
  }

  onAttributeNameChange = (event, { newValue }) => {
    this.setState({ attributeName: newValue });
  }

  onAttributeTypeChange = (option) => {
    this.setState({ attributeType: option });
  }

  onAddAttribute = () => {
    this.props.onAttributeAdded({
      attributeName: this.state.attributeName,
      attributeType: this.state.attributeType.value,
    });

    this.setState({ attributeName: '', attributeType: null });
  }

  onSuggestionSelected = (event, { suggestionValue }) => {
    this.setState({
      attributeName: suggestionValue,
      attributeType: filter(AttributeCreation.options, { value: this.props.suggestiveModel[suggestionValue].type })[0],
    });
  }

  isInValidInput = existingAttribute => this.state.attributeName === ''
      || this.state.attributeType === null || existingAttribute.length > 0;

  getSuggestions = () => reduce(Object.keys(this.props.suggestiveModel), (filteredSuggestions, suggestionName) => {
    if ((this.state.attributeType && this.state.attributeType.value === this.props.suggestiveModel[suggestionName].type && includes(suggestionName, this.state.attributeName))
    || (!this.state.attributeType && includes(suggestionName.toLowerCase(), this.state.attributeName.toLowerCase()))) {
      filteredSuggestions.push(suggestionName);
    }
    return filteredSuggestions;
  }, []);

  static options = [
    { value: 'text', label: 'Text' },
    { value: 'number', label: 'Number' },
    { value: 'range', label: 'Range' },
    { value: 'formula', label: 'Formula' },
  ];

  render() {
    const existingAttribute = filter(this.props.attributeModel, { attributeName: this.state.attributeName });
    const disableAddbtn = this.isInValidInput(existingAttribute);
    const helpText = existingAttribute.length > 0 ? 'Attribute already exists' : '';
    const textFieldStyle = existingAttribute.length > 0 ? 'error' : '';
    const filterSuggestions = this.getSuggestions();
    const inputProps = {
      name: 'normal',
      label: 'Attribute Name',
      className: 'attribute-name',
      onChange: this.onAttributeNameChange,
      value: this.state.attributeName,
      helpText,
      bsStyle: textFieldStyle,
    };

    return (
      <div className="creation-container">
        <AutoSuggestTextField
          suggestions={filterSuggestions}
          inputProps={inputProps}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={this.onSuggestionSelected}
        />
        <Select
          isClearable
          className="attribute-type"
          value={this.state.attributeType}
          label="Attribute Type"
          options={AttributeCreation.options}
          onChange={this.onAttributeTypeChange}
        />
        <Button
          type="default"
          onClick={this.onAddAttribute}
          className="add-button"
          style={{ border: 'none' }}
          disabled={disableAddbtn}
        >
          <IconAddCircle size="2x" />
        </Button>
      </div>
    );
  }
}

AttributeCreation.propTypes = {
  onAttributeAdded: PropTypes.func,
};

AttributeCreation.defaultProps = {
  onAttributeAdded: () => null,
};
export default AttributeModelContextConsumer(AttributeCreation);
