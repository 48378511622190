import React, { useState, Fragment } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@cimpress/react-components/lib/Button';
import { ProductDataSheetsEditor } from '@cimpress-technology/ruleset-constraint-editors';

import DataSheetSummary from './dataSheetSummary';
import { optionShape, dataSheetsShape } from '../shared/propTypes';

import messages from './messages';

const DataSheetsContainer = ({ dataSheets, options, updateDataSheets, onDelete }) => {
  const { formatMessage } = useIntl();
  const [dataSheetsEditMode, setDataSheetsEditMode] = useState(false);

  const toggleEditDataSheets = () => {
    setDataSheetsEditMode(!dataSheetsEditMode);
  };

  return (
    <Fragment>
      {dataSheetsEditMode && (
        <Fragment>
          <Button type="primary" onClick={toggleEditDataSheets}>
            {formatMessage(messages.backToDataSheetsSummary)}
          </Button>
          <ProductDataSheetsEditor
            options={Object.values(options)}
            dataSheets={dataSheets}
            onDataSheetsChanged={updateDataSheets}
          />
        </Fragment>
      )}
      {!dataSheetsEditMode &&
        (dataSheets.length ? (
          dataSheets.map((dataSheet, index) => (
            <DataSheetSummary
              key={`${dataSheet.name}: ${index}`}
              dataSheet={dataSheet}
              deleteKey={index}
              options={options}
              onDelete={onDelete}
              onEdit={toggleEditDataSheets}
            />
          ))
        ) : (
          <div>{formatMessage(messages.noDataSheetsConfigured)}</div>
        ))}
    </Fragment>
  );
};

DataSheetsContainer.propTypes = {
  dataSheets: PropTypes.arrayOf(PropTypes.shape(dataSheetsShape)),
  options: PropTypes.objectOf(PropTypes.shape(optionShape)).isRequired,
  updateDataSheets: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DataSheetsContainer;
