import find from 'lodash/find';

export const matchesCaseInsensitive = (a, b) => a.toUpperCase() === b.toUpperCase();

export const validateRuleName = (ruleName, optionConstraints, type) => {
  const existingOptionConstraint = find(optionConstraints, optionConstraint =>
    matchesCaseInsensitive(optionConstraint.name, ruleName)
  );

  if (existingOptionConstraint) {
    return `There is already an Option Constraint ${type} with the name ${existingOptionConstraint.name}.  Names must be unique.`;
  }

  return null;
};

export default validateRuleName;
