/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { TextField, Button, Select } from '@cimpress/react-components';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import './AttributeDefinition.css';
import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';

class AttributeRangeDefinition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimum: '',
      maximum: '',
      increment: '',
      existingSelection: '',
    };
  }


  onMinimumChange = (event) => {
    this.setState({ minimum: event.target.value ? toNumber(event.target.value) : '' });
  }

  onMaxiumumChange = (event) => {
    this.setState({ maximum: event.target.value ? toNumber(event.target.value) : '' });
  }

  onIncrementChange = (event) => {
    this.setState({ increment: event.target.value ? toNumber(event.target.value) : '' });
  }

  onExistingRangeSelectionChange = (selectedSingle) => {
    this.setState({ existingSelection: selectedSingle });
  };

  onValueAdded = () => {
    const rangeValue = {};

    rangeValue.minimum = toString(this.state.minimum);
    if (this.state.maximum) {
      rangeValue.maximum = toString(this.state.maximum);
    }
    if (this.state.increment) {
      rangeValue.increment = toString(this.state.increment);
    }

    this.props.onAttributeValueAdded(rangeValue);

    this.setState({
      minimum: '',
      maximum: '',
      increment: '',
    });
  }

  onExistingRangeAdded = () => {
    const rangeValue = {};

    rangeValue.minimum = toString(this.state.existingSelection.value.minimum);
    if (this.state.existingSelection.value.maximum) {
      rangeValue.maximum = toString(this.state.existingSelection.value.maximum);
    }
    if (this.state.existingSelection.value.increment) {
      rangeValue.increment = toString(this.state.existingSelection.value.increment);
    }

    this.props.onAttributeValueAdded(rangeValue);
    this.setState({
      existingSelection: '',
    });
  }

  render() {
    const options = this.props.suggestions.map(suggestion => (
      {
        value: suggestion,
        label: ` ${suggestion.minimum} to ${suggestion.maximum ? suggestion.maximum : 'Infinity'} ${suggestion.increment ? ` increment ${suggestion.increment}` : ''}`,
      }));
    const disableAddBtn = !this.state.minimum
    || (this.state.maximum && this.state.maximum < this.state.minimum)
    || (this.state.increment && this.state.maximum && this.state.increment >= this.state.maximum);

    return (
      <React.Fragment>
        <div className="definition-container range-container">
          <Select
            inline
            value={this.state.existingSelection}
            options={options}
            tether
            helpText="Select Ranges defined on the product"
            styles={{
              menuPortal: base => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            maxMenuHeight={150}
            menuPortalTarget={document.body}
            onChange={this.onExistingRangeSelectionChange}
          />
          <Button
            type="default"
            className="add-value-btn"
            style={{ border: 'none' }}
            disabled={this.state.existingSelection === ''}
            onClick={this.onExistingRangeAdded}
          >
            <IconAddCircle size="2x" />
          </Button>
        </div>
        <div className="definition-container">
          <TextField
            name="minimumValue"
            label="Minimum"
            type="number"
            helpText="Minimum for the range."
            onChange={this.onMinimumChange}
            value={this.state.minimum}
          />
          <TextField
            name="maximumValue"
            label="Maximum"
            type="number"
            helpText="Maximum for the range."
            onChange={this.onMaxiumumChange}
            value={this.state.maximum}
          />
          <TextField
            name="incrementValue"
            label="Increment"
            type="number"
            helpText="Increment for the range."
            onChange={this.onIncrementChange}
            value={this.state.increment}
          />
          <Button
            type="default"
            className="add-value-btn"
            style={{ border: 'none' }}
            disabled={disableAddBtn}
            onClick={this.onValueAdded}
          >
            <IconAddCircle size="2x" />
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default AttributeRangeDefinition;
