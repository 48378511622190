import en from './en.json';
import it from './it.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import nl from './nl.json';

export default {
  en,
  it,
  de,
  es,
  fr,
  nl,
};
