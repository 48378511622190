import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@cimpress/react-components/lib/TextField';
import './AutoSuggestTextField.css';

const AutoSuggestTextField = props => (
  <div className="auto-suggest-wrapper">
    <Autosuggest
      suggestions={props.suggestions}
      onSuggestionsFetchRequested={props.onSuggestionsFetchRequested}
      onSuggestionsClearRequested={props.onSuggestionsClearRequested}
      getSuggestionValue={x => x}
      renderSuggestion={x => <div className="suggestion-item">{x}</div>}
      renderInputComponent={inputProps => <TextField {...inputProps} />}
      inputProps={props.inputProps}
      shouldRenderSuggestions={() => true}
      onSuggestionSelected={props.onSuggestionSelected}
    />
  </div>
);

AutoSuggestTextField.propTypes = {
  inputProps: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
};

export default AutoSuggestTextField;
