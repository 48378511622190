export default {
  services: {
    introductionApi: {
      uri: 'https://introduction.products.cimpress.io',
    },
    ruleApi: {
      uri: 'https://rule.products.cimpress.io',
    },
    productApi: {
      uri: 'https://api.products.cimpress.io',
    },
  },
};
