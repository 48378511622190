import isNil from 'lodash/isNil';

const MIN_IDX = 1;
const MAX_IDX = 3;
const INCREMENT_IDX = 5;
const RANGE_STRING_REGEX = /^([0-9.]+)(-([0-9.]*|infinity)|[+])?(#([0-9.]+))?$/i;
const INFINITY = 'INFINITY';

export const isRangeTextValid = rangeText => {
  const trimmedRangeText = rangeText.trim();

  return trimmedRangeText.length !== 0 && Boolean(trimmedRangeText.match(RANGE_STRING_REGEX));
};

export function parseRange(rangeText) {
  const trimmedRangeText = rangeText.trim();

  const match = trimmedRangeText.match(RANGE_STRING_REGEX);
  if (match) {
    const range = {
      minimum: match[MIN_IDX],
    };

    if (!isNil(match[MAX_IDX]) && match[MAX_IDX] !== '') {
      if (match[MAX_IDX].toUpperCase() !== INFINITY) {
        range.maximum = match[MAX_IDX];
      }
    } else if (isNil(match[MAX_IDX]) && isNil(match[INCREMENT_IDX])) {
      range.maximum = range.minimum;
    }

    if (!isNil(match[INCREMENT_IDX]) && match[INCREMENT_IDX] !== '') {
      range.increment = match[INCREMENT_IDX];
    }

    return range;
  }

  return null;
}
