import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Tag from '@cimpress/react-components/lib/Tag';

import { valueShape } from '../../shared/propTypes';

import { getRangeMessage } from '../../shared/formatters/rangeTranslator';

export const RangeValuesDisplay = ({ values, onValueRemoved }) => {
  const { formatMessage } = useIntl();

  return (
    <Fragment>
      {values.map(rangeValue =>
        rangeValue.range ? (
          <Tag
            value={formatMessage(getRangeMessage(rangeValue.range), rangeValue.range)}
            key={JSON.stringify(rangeValue.range)}
            removable
            onRemoveClick={() => onValueRemoved(rangeValue)}
          />
        ) : null
      )}
    </Fragment>
  );
};

RangeValuesDisplay.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape(valueShape)).isRequired,
  onValueRemoved: PropTypes.func.isRequired,
};

RangeValuesDisplay.defaultProps = {
  values: [],
};
