import React, {
  useState, useEffect, useImperativeHandle, useRef,
} from 'react';
import PropTypes from 'prop-types';
import GenericAttributeModel from '@cimpress-technology/generic-attribute-model';
import { Tag, Label, Snackbar } from '@cimpress/react-components';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import SearchBar from './SkuSearch';
import './SkuAttributeModelBuilder.css';
import { getAggregatedSuggestiveModel } from './utils/suggestiveModelHelper';
import { getAllProductDefinitions } from './repository/productRepository';
import ErrorBoundary from './utils/ErrorBoundary';

const SkuAttributeModelBuilder = React.forwardRef((props, ref) => {
  const [suggestionModel, setSuggestionModel] = useState({});
  const [mcpSkus, setMcpSkus] = useState(props.mcpSkus);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const gamRef = useRef(null);

  const onSearchCompleted = (error, results) => {
    if (error) {
      setErrorMessage(`${get(error, 'response.status')}: Failed to fetch skus.`);
    } else if (results.length > 0) {
      const skuMap = new Set(mcpSkus);
      results.forEach((result) => {
        skuMap.add(result.referenceId);
      });
      setMcpSkus([...skuMap]);
    }
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    async publish() {
      const publishResponse = await gamRef.current.onPublish();
      if (!isEmpty(publishResponse)) {
        publishResponse.mcpSkus = mcpSkus;
      }
      return publishResponse;
    },
  }));

  const onSearchInitiated = () => { setLoading(true); };

  const buildSuggestionModel = async (skus) => {
    let aggregatedSuggestiveModel = {};
    try {
      const productDefinitions = await getAllProductDefinitions({ token: props.token, referenceIds: skus });
      aggregatedSuggestiveModel = getAggregatedSuggestiveModel(productDefinitions);
      setSuggestionModel(aggregatedSuggestiveModel);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setErrorMessage(`${get(error, 'response.status')}: Failed to fetch skus.`);
    }
    setLoading(false);
  };

  const onAttributeModelPublished = (status, resourceUrl, attributeModel) => {
    props.onPublish(status, { resourceUrl, mcpSkus, attributeModel });
  };

  const onClearAll = () => { setMcpSkus([]); };

  const onRemoveSku = (value) => {
    const filteredSkus = filter(mcpSkus, skuId => skuId !== value);
    setMcpSkus(filteredSkus);
  };

  const onHideSnackBar = () => { setErrorMessage(''); };

  useEffect(() => {
    buildSuggestionModel(mcpSkus);
  }, [mcpSkus, props.token]);

  useEffect(() => {
    setMcpSkus(props.mcpSkus);
  }, [props.mcpSkus]);

  return (
    <ErrorBoundary>
      <React.Fragment>
        {!props.isReadOnly ? (
          <React.Fragment>
            <SearchBar
              loading={loading}
              onSearchInitiated={onSearchInitiated}
              onSearchCompleted={onSearchCompleted}
              token={props.token}
            />
            <div className="sku-preview">
              {mcpSkus.map(productId => (
                <Tag key={productId} value={productId} removable onRemoveClick={onRemoveSku} />
              ))}
            </div>
            {mcpSkus.length > 0
              ? <Label text="Clear all" type="primary" onClick={onClearAll} className="clear-all" /> : null
            }
          </React.Fragment>
        ) : (
          <div className="sku-preview">
            {mcpSkus.map(productId => (
              <Tag key={productId} value={productId} onRemoveClick={onRemoveSku} />
            ))}
          </div>
        )}
        <GenericAttributeModel
          token={props.token}
          attributeModel={props.attributeModel}
          suggestiveModel={suggestionModel}
          onPublish={onAttributeModelPublished}
          resourceUrl={props.resourceUrl}
          isProduction={props.isProduction}
          isReadOnly={props.isReadOnly}
          ref={gamRef}
          showPublishBtn={props.showPublishBtn}
          allowSnackbarMessage={props.allowSnackbarMessage}
        />
        <Snackbar show={props.allowSnackbarMessage && !isEmpty(errorMessage)} bsStyle="danger" delay={2000} onHideSnackbar={onHideSnackBar}>
          {errorMessage}
        </Snackbar>
      </React.Fragment>
    </ErrorBoundary>
  );
});

SkuAttributeModelBuilder.propTypes = {
  token: PropTypes.string.isRequired,
  mcpSkus: PropTypes.arrayOf(PropTypes.string),
  resourceUrl: PropTypes.string,
  onPublish: PropTypes.func,
  isProduction: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  showPublishBtn: PropTypes.bool,
  allowSnackbarMessage: PropTypes.bool,
  attributeModel: PropTypes.shape(),
};

SkuAttributeModelBuilder.defaultProps = {
  attributeModel: {},
  mcpSkus: [],
  resourceUrl: '',
  onPublish: () => { },
  isProduction: false,
  isReadOnly: false,
  showPublishBtn: true,
  allowSnackbarMessage: true,
};

export default SkuAttributeModelBuilder;
