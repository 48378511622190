import React, { Fragment, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Modal from '@cimpress/react-components/lib/Modal';
import Button from '@cimpress/react-components/lib/Button';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';

import messages from './messages';

export const FormulaInfoModal = props => {
  const { formatMessage } = useIntl();

  const [showModal, updateShowModal] = useState(false);

  return (
    <Fragment>
      <div className="clickable" onClick={() => updateShowModal(true)} {...props}>
        <IconInformationCircle />
      </div>
      <Modal
        bsStyle="info"
        show={showModal}
        onRequestHide={() => updateShowModal(false)}
        title={formatMessage(messages.formulaInstructionsTitle)}
        closeButton={true}
        footer={<Button onClick={() => updateShowModal(false)}>{formatMessage(messages.closeFormulaInfo)}</Button>}>
        <FormattedMessage
          {...messages.formulaInstructions}
          values={{
            p: (...msg) => {
              return <p>{msg}</p>;
            },
            code: (...msg) => {
              return <code>{msg}</code>;
            },
            b: msg => <b>{msg}</b>,
          }}
        />
      </Modal>
    </Fragment>
  );
};
